import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import DotsIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import BinIcon from '@app/assets/icons/bin.svg?react';
import AddCustomersIcon from '@app/assets/icons/add-customers.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';

export const ListMenu = ({
  id,
  addCustomerToList,
  deleteListAction,
  simplified,
}: {
  id: string;
  deleteListAction?: () => void;
  addCustomerToList?: () => void;
  simplified?: boolean;
}) => {
  return (
    <Menu>
      <MenuButton className="group/user flex items-center justify-center gap-2">
        <DotsIcon />
      </MenuButton>
      <MenuItems
        transition
        anchor="left start"
        className="p-2 bg-white shadow-lg mt-2 min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 rounded-lg border file:border-base-antiFlash"
      >
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/customers/contacts/${id}`}
          >
            <FolderIcon
              width={20}
              height={20}
              className="[&_path]:stroke-brand-darkBlue3"
            />{' '}
            Open list
          </Link>
        </MenuItem>

        {!simplified && (
          <MenuItem>
            <button
              className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
              {...(addCustomerToList && { onClick: addCustomerToList })}
            >
              <AddCustomersIcon /> Add customer(s)
            </button>
          </MenuItem>
        )}
        <MenuItem>
          <button
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            {...(deleteListAction && { onClick: deleteListAction })}
          >
            <BinIcon className="[&_path]:stroke-system-error" /> Delete list
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
