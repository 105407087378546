import { Button, HookedForm, TextField } from '@app/components';
import { Link } from 'react-router-dom';
import { useLeaveReview } from './LeaveReview.hooks';
import { Star } from '@app/components/Star/Star';
import AttachIcon from '@app/assets/icons/attach.svg?react';
import { AIDropdown } from '@app/components/AIDropdown/AIDropdown';
import { BAD_REVIEW_REASONS } from '@app/utils/consts';
import clsx from 'clsx';

export const LeaveReview = () => {
  const {
    leaveReviewForm,
    handleStarClick,
    ratingValue,
    actions,
    handleChange,
    thumbnails,
    removeFile,
    handleSubmit,
    handleBadReason,
    requestData,
  } = useLeaveReview();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6 gap-6">
      <div className="bg-white text-center space-y-4 rounded max-w-[845px] w-full py-6 px-20">
        <div className="flex items-center gap-2">
          <div
            className="w-12 h-12 flex items-center justify-center  rounded-lg shrink-0"
            style={{ color: `#424244`, background: `#42424414` }}
          >
            <span className="font-bold text-lg">
              {requestData?.data?.business?.name?.charAt(0)}
            </span>
          </div>
          <div className="flex flex-col">
            <h5 className="font-bold mr-auto break-all text-lg leading-none">
              {requestData?.data?.business?.name}
            </h5>
            <p className="text-base-coolGray text-sm">
              {requestData?.data?.business?.address}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white space-y-4 rounded max-w-[845px]  py-10 px-20 w-full pb-6">
        <div>
          <h2 className="font-bold text-3xl leading-none">Leave a review</h2>
          <p className="text-base-coolGray mt-2">
            How would you rate your experience with{' '}
            {requestData?.data?.business?.name} ?
          </p>
        </div>
        <hr className="border-base-antiFlash" />
        <HookedForm
          formHook={leaveReviewForm}
          onSubmit={handleSubmit}
          className="flex gap-2 flex-col space-y-6"
        >
          <div>
            <h4 className="text-lg font-bold">Select stars to rate</h4>
            <ul className="flex gap-2 p-0 mt-4">
              {Array.from({ length: 5 }).map((_, index) => (
                <li key={index}>
                  <Star
                    active={ratingValue >= index + 1}
                    onClick={() => handleStarClick(index)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <hr className="border-base-antiFlash" />
          {leaveReviewForm?.watch('rating') < 3 && (
            <div>
              <h4 className="text-lg font-bold">
                Select what made your experience bad
              </h4>
              <ul className="flex gap-2 p-0 mt-4 flex-wrap">
                {BAD_REVIEW_REASONS.map((reason) => (
                  <button
                    key={reason.value}
                    type="button"
                    onClick={() => handleBadReason(reason.value)}
                    className={clsx(
                      'rounded-full border border-base-ghostWhite bg-white shrink-0 py-1 px-2 text-base-davyGray text-[15px] cursor-pointer',
                      {
                        'bg-brand-lightBlue4': leaveReviewForm
                          ?.watch('reasons')
                          ?.includes(reason.value),
                      }
                    )}
                  >
                    {reason.label}
                  </button>
                ))}
              </ul>
            </div>
          )}
          <div className="flex items-center justify-between gap-2">
            <h4 className="text-lg font-bold">Review</h4>
            <AIDropdown actions={actions} />
          </div>
          <div>
            <TextField
              name="review"
              placeholder="Describe your case so we can help you solve it"
            />
            <p className="text-[10px] text-base-coolGray">
              By leaving this review, you accept our{' '}
              <Link to="/" className="text-brand-baseBlue">
                Privacy Policy
              </Link>
            </p>
          </div>
          <label
            htmlFor="file"
            className="w-fit font-medium text-base-davyGray bg-base-ghostWhite p-2 rounded-lg cursor-pointer flex gap-2 items-center"
          >
            <AttachIcon />
            Attach photos or videos
          </label>
          <input
            id="file"
            name="images"
            type="file"
            multiple
            className="hidden"
            onChange={handleChange}
          />
          {thumbnails.length > 0 && (
            <div className="flex gap-2">
              {thumbnails.map((thumb, index) => (
                <div
                  key={index}
                  className="w-16 h-16 relative rounded-[4px] overflow-hidden"
                >
                  <img
                    src={thumb}
                    className="absolute w-full h-full object-cover object-center"
                  />
                  <div className="bg-black/30 inset-0 absolute" />
                  <button
                    onClick={() => removeFile(index)}
                    type="button"
                    className="h-6 w-6 bg-white/50 flex items-center justify-center top-0 right-0 absolute z-10"
                  >
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="shrink-0"
                    >
                      <path
                        d="M6.5 1.5L1.5 6.5M6.5 6.5L1.5 1.5"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          )}
          <hr className="border-base-antiFlash" />
          <Button type="submit">Leave this review</Button>
        </HookedForm>
      </div>
      <p className="mt-auto text-base-coolGray">
        Powered by
        <Link to="/sign-up" className="ml-2 text-brand-baseBlue font-bold">
          Echo Ratings
        </Link>
      </p>
    </div>
  );
};
