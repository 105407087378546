import { CreateListRequest, CreateTagRequest } from '@app/models/requests';
import { ListInterface } from '@app/models/util';
import { getCustomers } from '@app/services/customers';
import { createList, getLists, getPaginatedLists } from '@app/services/list';
import { createTag } from '@app/services/tag';
import {
  createListValidation,
  createTagValidation,
} from '@app/utils/validations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useListPage = () => {
  const [addListOpen, setAddListOpen] = useState<boolean>(false);
  const [addTagOpen, setAddTagOpen] = useState<boolean>(false);
  const [addCustomersToList, setAddCustomersToList] = useState<boolean>(false);
  const [listQuery, setListQuery] = useState({ page: 1, limit: 10 });
  const [selectedLists, setSelectedLists] = useState<string[]>([]);
  const createListForm = useForm({ resolver: createListValidation });
  const createTagForm = useForm({ resolver: createTagValidation });

  const { data: allListsData } = useQuery<{
    lists: ListInterface[];
    count: number;
  }>({
    queryKey: ['all-lists'],
    queryFn: () => {
      return getLists()
        .then((res) => res?.data)
        .catch((err) => err);
    },
  });

  const {
    data: listsData,
    isLoading,
    refetch,
  } = useQuery<{ lists: ListInterface[]; count: number }>({
    queryKey: ['lists-paginated'],
    queryFn: () => {
      const limit = listQuery?.limit ?? 10;
      const page = listQuery?.page ?? 1;
      const skip = (page - 1) * limit;

      return getPaginatedLists({
        pagination: { limit, page, skip },
      })
        .then((res) => res?.data)
        .catch((err) => err);
    },
  });

  const { data: customers } = useQuery({
    queryKey: ['customers-all'],
    queryFn: () => getCustomers(),
  });

  const handleAddList = () => {
    setAddListOpen(!addListOpen);
    if (!addListOpen) createListForm.reset();
  };

  const handleAddTag = () => {
    setAddTagOpen(!addTagOpen);
    if (!addTagOpen) createTagForm.reset();
  };

  const handleAddCustomersToList = () => {
    setAddCustomersToList(!addCustomersToList);
  };

  const handleSelectedLists = (id: string | undefined) => {
    if (id) {
      if (id === 'all') {
        if (selectedLists.length === listsData?.lists.length) {
          setSelectedLists([]);
        } else {
          setSelectedLists(listsData?.lists.map((item) => item.id) ?? []);
        }
        return;
      }

      if (selectedLists.includes(id)) {
        setSelectedLists((prev) => prev.filter((item) => item !== id));
      } else {
        setSelectedLists((prev) => [...prev, id]);
      }
    }
  };

  const { mutateAsync: addList, isPending: isPendingList } = useMutation({
    mutationKey: ['add-list'],
    mutationFn: (data: CreateListRequest) => createList(data),
    onSuccess: () => {
      refetch();
      handleAddList();
      createListForm.reset();
    },
    onError: (err) => {
      toast.error('Error creating list');
      console.error(err);
    },
  });
  const { mutateAsync: addTag, isPending: isPendingTag } = useMutation({
    mutationKey: ['add-tag'],
    mutationFn: (data: CreateTagRequest) => createTag(data),
    onSuccess: () => {
      handleAddTag();
      createTagForm.reset();
    },
    onError: (err) => {
      toast.error('Error creating tag');
      console.error(err);
    },
  });

  const submitList = async (values: FieldValues) => {
    await addList(values as CreateListRequest);
  };

  const submitTag = async (values: FieldValues) => {
    await addTag(values as CreateTagRequest);
  };

  const handleNextPage = () => {
    setListQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handlePrevPage = () => {
    setListQuery((prev) => ({
      ...prev,
      page: prev.page === 0 ? 0 : prev.page - 1,
    }));
  };

  const handleLimit = (value: any) => {
    setListQuery((prev) => ({ ...prev, limit: value }));
  };

  useEffect(() => {
    if (listsData) {
      refetch();
    }
  }, [listQuery.limit, listQuery.page]);

  return {
    addListOpen,
    addTagOpen,
    handleAddList,
    handleAddTag,
    createListForm,
    createTagForm,
    submitList,
    submitTag,
    isPendingList,
    isPendingTag,
    listsData,
    isLoading,
    customers,
    handleNextPage,
    handlePrevPage,
    handleLimit,
    listQuery,
    refetch,
    handleAddCustomersToList,
    addCustomersToList,
    handleSelectedLists,
    selectedLists,
    allListsData,
  };
};
