import {
  Button,
  CheckSelectField,
  DateField,
  HookedForm,
  InputField,
  SelectField,
  Tabs,
} from '@app/components';
import { useRatingsReviews } from './RatingsReviews.hooks';
import { PreviewReview } from '@app/components/PreviewReview/PreviewReview';
import { ReviewBox } from '@app/components/ReviewBox/ReviewBox';
import { BusinessReviews } from '@app/models/reviews';
import NoReviewsIllustration from '@app/assets/illustrations/no-reviews.svg?react';

export const RatingsReviews = () => {
  const {
    filtersForm,
    requestData,
    handleReviewClick,
    handleTabClick,
    activeReview,
    tabOptions,
  } = useRatingsReviews();

  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between items-center">
          <h2 className="font-bold text-[28px]">Reviews</h2>
        </div>
        {requestData?.data?.count > 0 && (
          <>
            <div className="container mx-auto px-4">
              <Tabs
                tabs={tabOptions}
                onClick={handleTabClick}
                defaultValue={'all'}
              />
            </div>
            <hr />
            <div className="container mx-auto p-4">
              <HookedForm className="flex gap-4 ">
                <DateField sizeStyle="sm" name="start" containerClass="w-36" />
                <DateField sizeStyle="sm" name="end" containerClass="w-36 " />
                <CheckSelectField
                  placeholder="Platforms"
                  sizeStyle="sm"
                  name="platforms"
                  options={[{ label: 'All platforms', value: 'all' }]}
                />
                <CheckSelectField
                  placeholder="Ratings"
                  sizeStyle="sm"
                  name="ratings"
                  options={[{ label: 'All ratings', value: 'all' }]}
                />
                <SelectField
                  placeholder="Sort by"
                  sizeStyle="sm"
                  defaultValue="newest-reviews"
                  name="sort"
                  options={[
                    { label: 'Newest reviews', value: 'newest-reviews' },
                  ]}
                />
              </HookedForm>
            </div>

            <div className="bg-white">
              <div className="container mx-auto py-6 px-4 flex flex-col md:flex-row justify-between items-end md:items-center gap-4">
                <h3 className="text-2xl font-bold">
                  {requestData?.data?.count ?? 0} reviews
                </h3>
                <div>
                  <HookedForm formHook={filtersForm}>
                    <InputField name="search" placeholder="Search" />
                  </HookedForm>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {requestData?.data?.count > 0 && (
        <div
          id="test"
          className="grid grid-cols-1 md:grid-cols-6 gap-4 min-h-screen"
        >
          <div className="col-span-2 bg-white h-full">
            <ul>
              {requestData?.data?.reviews?.map((review: BusinessReviews) => (
                <li
                  key={review.id}
                  onClick={() => handleReviewClick(review.id)}
                >
                  <PreviewReview
                    review={review}
                    active={activeReview === review.id}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="container mx-auto p-4 col-span-4">
            {activeReview && (
              <ReviewBox
                review={requestData?.data?.reviews?.find(
                  (review: BusinessReviews) => review.id === activeReview
                )}
              />
            )}
          </div>
        </div>
      )}
      {requestData?.data?.count === 0 && (
        <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
          <NoReviewsIllustration />
          <p className="font-bold">No reviews</p>
          <p className="text-sm text-base-coolGray text-center">
            You currently don't have any reviews.
            <br />
            Start a campaign to send review requests
            <br /> across your platforms.
          </p>
          <Button type="button">Schedule a campaign</Button>
        </div>
      )}
    </div>
  );
};
