import { Button, HookedForm, InputField, SelectField } from '@app/components';
import { useNavigate } from 'react-router-dom';
import Logo from '@app/assets/horizontal-logo.svg?react';
import BackIcon from '@app/assets/icons/back.svg?react';
import { useNewBusinessPage } from './NewBusiness.hooks';
import { BusinessCategoryInterface } from '@app/models/business';

export const NewBusiness = () => {
  const {
    handleSubmit,
    newBusinessForm,
    error,
    businessCategories,
    isLoading,
  } = useNewBusinessPage();
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6">
      <Logo width={139} height={110} />
      <div className="bg-white text-center space-y-4 rounded max-w-[510px] px-10 pt-10 pb-6 my-auto">
        <div>
          <Button
            kind="tertiary"
            className="min-w-fit !w-fit"
            leftNode={<BackIcon />}
            onClick={() => navigate('/select-business')}
          />
        </div>
        <h2 className="font-bold text-3xl">New business account</h2>
        <p className="text-base-coolGray">
          Just a few details needed to set up your account
        </p>

        {error && (
          <div className="bg-system-errorLight p-2 rounded-lg text-system-error font-medium ">
            <p>{error}</p>
          </div>
        )}
        <HookedForm
          formHook={newBusinessForm}
          onSubmit={handleSubmit}
          className="flex gap-2 flex-col"
        >
          <InputField name="name" type="text" placeholder="Business Name" />
          <InputField
            name="address"
            type="text"
            placeholder="Business Address"
          />
          <SelectField
            name="category"
            placeholder="Business Category"
            options={businessCategories?.categories?.map(
              (category: BusinessCategoryInterface) => ({
                value: category.id,
                label: category.name,
              })
            )}
          />
          <div className="flex items-center justify-center flex-col mt-2">
            <Button disabled={isLoading} className="w-full">
              Start 14-days free trial
            </Button>
          </div>
          <p className="text-base-frenchGray text-xs">
            By continuing, you agree to our Terms of Service and Privacy Policy
            .
          </p>
        </HookedForm>
      </div>
    </div>
  );
};
