import {
  Button,
  CheckSelectField,
  HookedForm,
  InputField,
  TextField,
} from '@app/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { updateFormData } from '@app/redux/features/campaignForm';

// Define expected form fields to ensure consistent structure
interface CampaignFormData {
  campaignName?: string;
  description?: string;
  platforms?: string[];
  businessName?: string;
  businessAddress?: string;
  businessEmail?: string;
  type?: string;
  selectedCustomers?: string[];
  selectAll?: boolean;
  emailTemplate?: string;
  textTemplate?: string;
  sendImmediately?: boolean;
  initialSendDate?: string;
  sendFollowUp?: boolean;
  followUpDays0?: string;
  followUpPeriod0?: string;
  followUpDays1?: string;
  followUpPeriod1?: string;
  followUpDays2?: string;
  followUpPeriod2?: string;
  useAi?: boolean;
}

export const CreateCampaignSettings = ({
  form,
}: {
  form: UseFormReturn<FieldValues, any, undefined>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.campaignForm.formData);
  const selectedBusiness = useAppSelector((state) => state.business.selected);

  // Helper function to clean and structure form data
  const processFormData = (data: FieldValues): CampaignFormData => {
    const cleanedData: CampaignFormData = {};
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        cleanedData[key as keyof CampaignFormData] = value;
      }
    });
    return cleanedData;
  };

  // Register form fields with validation
  useEffect(() => {
    form.register('campaignName', {
      required: 'Campaign name is required',
    });
    form.register('description');
    form.register('platforms', {
      required: 'Please select at least one platform',
    });
    form.register('businessName', {
      required: 'Business name is required',
    });
    form.register('businessAddress');
    form.register('businessEmail', {
      required: 'Business email is required',
      pattern: {
        value: /\S+@\S+\.\S+/,
        message: 'Invalid email address',
      },
    });
  }, [form]);

  // Load form data and set up tracking
  useEffect(() => {
    // First check localStorage for saved form data
    const storedFormData = localStorage.getItem('campaignFormData');
    if (storedFormData) {
      try {
        const parsedData = JSON.parse(storedFormData) as CampaignFormData;
        Object.keys(parsedData).forEach((key) => {
          const typedKey = key as keyof CampaignFormData;
          if (parsedData[typedKey] !== undefined) {
            form.setValue(key, parsedData[typedKey]);
          }
        });
      } catch (error) {
        localStorage.removeItem('campaignFormData');
      }
    }
    // Then check Redux state if localStorage didn't have data
    else if (formData) {
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== undefined) {
          form.setValue(key, formData[key]);
        }
      });
    }

    // Set business info if available
    if (selectedBusiness && !form.getValues('businessName')) {
      form.setValue('businessName', selectedBusiness.name);
      form.setValue(
        'businessAddress',
        (selectedBusiness as unknown as { address: string }).address
      );
    }

    // Track form changes and save to Redux/localStorage
    const subscription = form.watch((value) => {
      const processedData = processFormData(value);
      dispatch(updateFormData(processedData));
      try {
        localStorage.setItem('campaignFormData', JSON.stringify(processedData));
      } catch (error) {
        // Silent fail for localStorage errors
      }
    });

    return () => subscription.unsubscribe();
  }, [form, formData, selectedBusiness, dispatch]);

  const handleNext = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const rawValues = form.getValues();
      const processedData = processFormData(rawValues);

      // Save form data before proceeding to next step
      dispatch(updateFormData(processedData));
      try {
        localStorage.setItem('campaignFormData', JSON.stringify(processedData));
      } catch (error) {
        // Silent fail for localStorage errors
      }

      // Navigate to next step
      navigate('/campaigns/create/customers');
    }
  };

  return (
    <div className="container mx-auto p-4 flex flex-col justify-between items-center gap-4">
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Campaign information</h4>
          <p className="text-xs text-base-coolGray">
            Provide general information about this new campaign
          </p>
        </div>
        <hr />
        <HookedForm formHook={form} className="space-y-4">
          <InputField
            name="campaignName"
            label="Campaign name"
            onChange={(e) => {
              form.setValue('campaignName', e.target.value, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
          />
          <TextField name="description" label="Description" />
        </HookedForm>
      </div>
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Send campaign via</h4>
          <p className="text-xs text-base-coolGray">
            Provide general information about this new campaign
          </p>
        </div>
        <hr />
        <HookedForm formHook={form}>
          <CheckSelectField
            placeholder="Email, text or both"
            name="platforms"
            label="Platforms"
            options={[
              { label: 'Email', value: 'email' },
              { label: 'Text', value: 'sms' },
            ]}
          />
        </HookedForm>
      </div>
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Sender</h4>
          <p className="text-xs text-base-coolGray">
            Fill in the sender's details
          </p>
        </div>
        <hr />
        <HookedForm formHook={form} className="space-y-4">
          <InputField
            name="businessName"
            label="Business name"
            defaultValue={selectedBusiness?.name}
            disabled={!!selectedBusiness}
          />
          <InputField
            name="businessAddress"
            label="Business address"
            defaultValue={
              (selectedBusiness as unknown as { address: string })?.address
            }
          />
          <hr />
          <InputField name="businessEmail" label="Business email" />
        </HookedForm>
      </div>

      <div className="flex justify-end mt-4 w-full max-w-[800px]">
        <Button type="button" onClick={handleNext}>
          Next: Select Customers
        </Button>
      </div>
    </div>
  );
};
