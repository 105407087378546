import { CheckboxField } from '../Fields/CheckboxField';
import { HookedForm } from '../HookedForm';
import { ListInterface } from '@app/models/util';
import { Pagination } from '../Pagination/Pagination';
import { ListRow } from '../ListRow/ListRow';
import DeleteIcon from '@app/assets/icons/bin.svg?react';
import AddCustomersIcon from '@app/assets/icons/add-customers.svg?react';

const tableHeader = [
  { label: 'LIST', field: 'name' },
  { label: 'ID', field: 'id' },
  { label: 'CUSTOMERS', field: 'customers' },
  { label: 'CREATION DATE', field: 'createdAt' },
];

interface ListTableInterface {
  lists: ListInterface[];
  handleNextPage: (value: number) => void;
  handlePrevPage: (value: number) => void;
  handleLimit: (value: number) => void;
  handleListSelect: (value: string | undefined) => void;
  total: number;
  page: number;
  refresh?: () => void;
  handleAddCustomersToList?: () => void;
}

export const ListsTable = ({
  lists,
  handleNextPage,
  handlePrevPage,
  handleLimit,
  total,
  page,
  refresh,
  handleListSelect,
  handleAddCustomersToList,
}: ListTableInterface) => {
  const hasSelected = true;
  return (
    <div className="bg-white overflow-x-auto">
      <HookedForm>
        <table className="w-full">
          <thead>
            <tr className="border-b border-base-antiFlash">
              <th className="p-4 min-w-fit w-0">
                <CheckboxField
                  onCheckMark={handleListSelect}
                  name="lists"
                  value="all"
                />
              </th>
              {hasSelected && (
                <th
                  colSpan={5}
                  className="text-sm text-brand-baseBlue font-medium p-4"
                  align="left"
                >
                  <div className="flex gap-4 ">
                    <button
                      onClick={() =>
                        handleAddCustomersToList && handleAddCustomersToList()
                      }
                      className="flex items-center gap-1"
                    >
                      <AddCustomersIcon className="[&_path]:fill-[#7fc9fe]" />{' '}
                      Add customer(s) to list
                    </button>
                    <button className="flex items-center gap-1">
                      <DeleteIcon /> Delete list(s)
                    </button>
                  </div>
                </th>
              )}
              {!hasSelected && (
                <>
                  {tableHeader.map((item) => (
                    <th
                      key={item.field}
                      className="p-4 text-xs font-medium text-left text-base-coolGray"
                    >
                      {item.label}
                    </th>
                  ))}
                </>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {lists?.map((list) => {
              return (
                <ListRow
                  key={list.id}
                  data={list}
                  refresh={refresh}
                  handleCheckbox={handleListSelect}
                />
              );
            })}
          </tbody>
        </table>
        <Pagination
          handleLimitChange={handleLimit}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          page={page}
          total={total}
        />
      </HookedForm>
    </div>
  );
};
