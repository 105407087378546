import { getPaginatedReviews } from '@app/services/reviews';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const useRatingsReviews = () => {
  // @ts-expect-error
  const [activeTab, setActiveTab] = useState<string>('all');
  const [activeReview, setActiveReview] = useState<string | undefined>(
    undefined
  );
  const filtersForm = useForm({});

  const {
    data: requestData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['all-reviews-requests'],
    queryFn: () => {
      const limit = filtersForm.getValues('limit') ?? 10;
      const pageValue = Number(filtersForm.getValues('page') ?? 1);

      const page = Number(
        pageValue === 0 ? 1 : (filtersForm.getValues('page') ?? 1)
      );

      const search = filtersForm.getValues('search') ?? '';

      return getPaginatedReviews({
        text: search,
        limit,
        page,
      });
    },
  });

  const handleReviewClick = (id: string) => {
    setActiveReview(id);
  };

  const tabOptions = [
    { label: 'All', value: 'all', count: Math.floor(Math.random() * 10) },
    {
      label: 'New reviews',
      value: 'new',
      count: Math.floor(Math.random() * 10),
    },
    {
      label: 'Needs response',
      value: 'pending',
      count: Math.floor(Math.random() * 10),
    },
    {
      label: 'Reviews with responses',
      value: 'answered',
      count: Math.floor(Math.random() * 10),
    },
  ];

  const handleTabClick = (value: string) => {
    setActiveTab(value);
  };

  return {
    requestData,
    isLoading,
    refetch,
    error,
    filtersForm,
    handleReviewClick,
    activeReview,
    tabOptions,
    handleTabClick,
  };
};
