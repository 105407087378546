import {
  BaseDialog,
  Button,
  HookedForm,
  InputField,
  Tabs,
} from '@app/components';
import { OptionInterface } from '@app/models/util';
import { GenericTable } from '@app/components/GenericTable/GenericTable';
import { CampaignsTableRow } from '@app/components/CampaignsTableRow/CampaignsTableRow';
import { CampaignsFilters } from '@app/components/CampaignsFilters/CampaignsFilters';
import { useCampaigns } from './Campaigns.hooks';
import CloseIcon from '@app/assets/icons/close.svg?react';
import CampaignsIcon from '@app/assets/icons/schedule-campaign.svg?react';
import CampaignScheduleIcon from '@app/assets/icons/menu/campaigns.svg?react';
import AutomationIcon from '@app/assets/icons/automation.svg?react';
import PlaneIcon from '@app/assets/icons/plane.svg?react';
import { deleteCampaign } from '@app/services/campaigns';
import { toast } from 'react-toastify';
import NoCampaignIllustration from '@app/assets/illustrations/no-campaigns.svg?react';

export const Campaigns = () => {
  const {
    createSecheduleModal,
    handleCreateScheduleModal,
    campaignsData,
    createAutomation,
    createCampaign,
    handleDeleteModal,
    deleteModal,
    refetch,
    filterForm,
    handleLimitChange,
    handleNextPage,
    handlePreviousPage,
    page,
  } = useCampaigns();

  const campaignsTabs = [
    { value: 'all', label: 'All' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'drafts', label: 'Drafts' },
  ];

  const deleteCampaignAction = async () => {
    if (!deleteModal) return;

    await deleteCampaign(deleteModal)
      .then(async () => {
        await refetch();
        toast.success('Campaign deleted successfully');
      })
      .catch(() => {
        toast.error('Failed to delete campaign');
      })
      .finally(() => handleDeleteModal(undefined));
  };
  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto pt-4 px-4 space-y-2">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <h2 className="font-bold text-[28px] mb-2">Campaigns</h2>
              <Button
                onClick={handleCreateScheduleModal}
                leftNode={<CampaignsIcon className="[&_path]:stroke-white" />}
              >
                Schedule new campaign
              </Button>
            </div>
            <CampaignsFilters filterForm={filterForm} />
            <Tabs
              tabs={campaignsTabs as unknown as OptionInterface[]}
              onClick={console.log}
              defaultValue={'all'}
            />
          </div>
        </div>
        <div className="bg-white">
          <div className="container mx-auto py-6 px-4 flex flex-col md:flex-row justify-between items-end md:items-center gap-4">
            <h3 className="text-2xl font-bold">
              {campaignsData?.count} campaigns
            </h3>
            <div>
              <HookedForm formHook={filterForm}>
                <InputField name="search" placeholder="Search" />
              </HookedForm>
            </div>
          </div>
        </div>
        <div className="container mx-auto py-6 px-4 ">
          {campaignsData?.count === 0 && (
            <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
              <NoCampaignIllustration />
              <p className="font-bold">No campaigns</p>
              <p className="text-sm text-base-coolGray text-center">
                You don't have any campaigns added yet.
                <br /> Create your first campaign
              </p>
              <Button
                onClick={handleCreateScheduleModal}
                leftNode={<CampaignsIcon className="[&_path]:stroke-white" />}
              >
                Schedule new campaign
              </Button>
            </div>
          )}
          {Boolean(campaignsData?.count && campaignsData?.count > 0) && (
            <div className="bg-white overflow-auto">
              <GenericTable
                data={campaignsData?.campaigns ?? []}
                total={campaignsData?.count ?? 0}
                currentPage={page}
                headers={[
                  { label: 'Campaigns', field: 'campaigns' },
                  { label: 'Status', field: 'status' },
                  { label: 'Follow up', field: 'follow-up' },
                  { label: 'Platforms', field: 'platforms' },
                  { label: 'Customers', field: 'customers' },
                  { label: 'Reviewed', field: 'reviewed' },
                ]}
                renderRow={(data) =>
                  data.map((row) => (
                    <CampaignsTableRow
                      key={row.id}
                      data={row}
                      deleteCampaign={() => handleDeleteModal(row.id)}
                    />
                  ))
                }
                handleNextPage={handleNextPage}
                handlePrevPage={handlePreviousPage}
                handleLimitChange={handleLimitChange}
                isLoading={false}
              />
            </div>
          )}
        </div>
      </div>
      <BaseDialog
        size="max-w-2xl"
        open={Boolean(deleteModal)}
        onClose={handleDeleteModal}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <CampaignsIcon width={24} height={24} />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg">Delete Campaigns</h5>
          </div>
          <div>
            <button type="button" onClick={() => handleDeleteModal()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <p className="text-base-davyGray">
            Are you sure you want to delete this campaign? All data about it
            will be deleted from the database.
          </p>
          <div></div>
          <div className="flex items-center gap-4 justify-center py-4">
            <Button
              kind="secondary"
              className="w-full"
              type="button"
              onClick={() => handleDeleteModal()}
            >
              Cancel
            </Button>
            <Button
              kind="danger"
              className="w-full"
              type="submit"
              onClick={() => deleteCampaignAction()}
            >
              Delete campaign
            </Button>
          </div>
        </div>
      </BaseDialog>
      <BaseDialog
        size="max-w-xl"
        open={createSecheduleModal}
        onClose={handleCreateScheduleModal}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <CampaignScheduleIcon width={24} height={24} />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg">Creat new campaign</h5>
          </div>
          <div>
            <button type="button" onClick={() => handleCreateScheduleModal()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4 space-y-4">
          <button
            type="button"
            onClick={() => createCampaign()}
            className="bg-base-seasalt rounded-lg flex p-4 gap-2 w-full"
          >
            <div className="bg-white rounded-[4px] flex items-center justify-center h-8 w-8">
              <PlaneIcon />
            </div>
            <div className="text-left">
              <p className="text-sm font-bold">Simple</p>
              <p className="text-base-coolGray text-sm">
                Create a one-off campaign that can be sent once
                <br /> or multiple times
              </p>
            </div>
          </button>
          <button
            type="button"
            onClick={() => createAutomation()}
            className="bg-base-seasalt rounded-lg flex p-4 gap-2 w-full"
          >
            <div className="bg-white rounded-[4px] flex items-center justify-center h-8 w-8">
              <AutomationIcon />
            </div>
            <div className="text-left">
              <p className="text-sm font-bold">Automation</p>
              <p className="text-base-coolGray text-sm">
                Create conditions for automatic email or text
                <br /> dispatches.
              </p>
            </div>
          </button>
        </div>
      </BaseDialog>
    </>
  );
};
