import { DateTime } from 'luxon';
import IPhoneIcon from '@app/assets/icons/iphone.svg?react';
import EmailIcon from '@app/assets/icons/email.svg?react';
import { CampaignInterface } from '@app/models/campaigns';
import { getCampaignStatusColor, renderPlataforms } from '@app/utils/utils';
import { CampaignMenu } from '@app/components';

export const CampaignsTableRow = ({
  data,
  deleteCampaign,
}: {
  data: CampaignInterface;
  deleteCampaign?: () => void;
}) => {
  return (
    <tr className="border-b border-base-antiFlash last-of-type:border-b-0 text-xs cursor-pointer hover:bg-brand-lightBlue4">
      <td className="p-4">
        <div className="flex gap-2">
          <div className="flex gap-1 flex-col">
            {data?.channel?.split(',')?.map((t: string) =>
              t === 'email' ? (
                <span
                  key={t}
                  className="bg-base-antiFlash p-1 rounded-sm h-6 w-6 flex items-center justify-center"
                >
                  <EmailIcon />
                </span>
              ) : (
                <span
                  key={t}
                  className="bg-base-antiFlash p-1 rounded-sm h-6 w-6 flex items-center justify-center"
                >
                  <IPhoneIcon />
                </span>
              )
            )}
          </div>
          <div>
            <p className="font-bold">{data.name}</p>
            <p className="text-xs text-base-coolGray">#{data?.id}</p>
            <p className="text-xs text-base-coolGray">
              <span>Created on: </span>
              <span>
                {DateTime.fromISO(data.createdAt).toFormat('MMM dd, yyyy')}
              </span>
            </p>
          </div>
        </div>
      </td>
      <td className="p-4">
        <span
          className="w-2 h-2 inline-block rounded-full mr-1"
          style={{ backgroundColor: getCampaignStatusColor(data?.status) }}
        ></span>
        <span className="capitalize">{data?.status}</span>
      </td>
      <td className="p-4">
        {data?.schedules?.[0]?.start && (
          <p>
            <span className="font-bold">
              <span>Initial send: </span>
            </span>
            {DateTime.fromFormat(
              data?.schedules?.[0]?.start,
              'd/M/yyyy'
            ).toFormat('MMM dd, yyyy')}
          </p>
        )}
        {data?.schedules?.[1]?.start && (
          <p>
            <span className="font-bold">
              <span>Send again: </span>
            </span>
            {DateTime.fromJSDate(
              new Date(data?.schedules?.[1]?.start)
            ).toFormat('MMM dd, yyyy')}
          </p>
        )}
      </td>
      <td className="p-4">
        <div className="flex gap-2">
          {renderPlataforms(data?.platforms?.split(','))}
        </div>
      </td>
      <td className="p-4" align="right">
        <span className="font-semibold text-base">{data.customers.length}</span>
      </td>
      <td className="p-4">
        <span>reviewed</span>
      </td>
      <td>
        <CampaignMenu deleteCampaign={deleteCampaign} />
      </td>
    </tr>
  );
};
