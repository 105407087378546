import {
  Button,
  ColumnChart,
  DateField,
  DonutChart,
  HookedForm,
  SelectField,
} from '@app/components';
import EmailIcon from '@app/assets/icons/email.svg?react';
import IphoneIcon from '@app/assets/icons/iphone.svg?react';
import MessageIcon from '@app/assets/icons/message.svg?react';
import AutomationIcon from '@app/assets/icons/automation.svg?react';
import { useEffect, useState } from 'react';
import {
  getCampaignsByTimeline,
  getCampaignsInformation,
} from '@app/services/analytics';

interface AnalyticsData {
  campaigns: any;
  timeline: any;
}

export const AnalyticsCampaigns = () => {
  const [data, setData] = useState<AnalyticsData | undefined>();
  useEffect(() => {
    Promise.all([getCampaignsInformation(), getCampaignsByTimeline()])
      .then(([campaigns, timeline]) => {
        setData({ campaigns: campaigns.data, timeline: timeline.data });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">Campaigns Analytics</h2>
          <div className="flex gap-2">
            <Button className="text-sm font-semibold" kind="secondary">
              Export as PDF
            </Button>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <HookedForm className="flex gap-4 ">
            <DateField name="start" containerClass="w-36" />
            <DateField name="end" containerClass="w-36 " />
            <SelectField
              defaultValue="all"
              name="connection"
              options={[{ label: 'All connections', value: 'all' }]}
            />
          </HookedForm>
        </div>
      </div>
      <div className="container mx-auto p-4 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white p-4 ">
            <div className="flex items-center justify-between">
              <h5 className="font-bold text-sm">Follow-ups</h5>
              <button className="bg-base-antiFlash p-2 rounded-lg">
                All campaigns
              </button>
            </div>
            <hr className="my-4" />
            <div className="space-y-4 flex gap-10 items-center justify-center">
              <div>
                <h4 className="text-6xl font-bold">{data?.campaigns?.total}</h4>
                <p className="text-base-davyGray text-sm">Active follow ups</p>
              </div>
              <div>
                <ul className="flex gap-2 flex-col">
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <div className="h-6 w-6 bg-base-antiFlash flex items-center justify-center">
                      <EmailIcon />
                    </div>

                    <span>Email</span>
                    <div className="rounded-full w-32 bg-brand-lightBlue3 h-2 overflow-hidden ">
                      <div className="bg-brand-baseBlue w-full h-full rounded-full" />
                    </div>
                    <span>{data?.campaigns?.total}</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <div className="h-6 w-6 bg-base-antiFlash flex items-center justify-center">
                      <IphoneIcon />
                    </div>

                    <span>SMS</span>
                    <div className="rounded-full w-32 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[0%] h-full rounded-full" />
                    </div>
                    <span>0</span>
                  </li>
                  <hr />
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <div className="h-6 w-6 bg-base-antiFlash flex items-center justify-center">
                      <MessageIcon
                        width={16}
                        height={16}
                        className="[&_path]:stroke-base-coolGray"
                      />
                    </div>

                    <span>Simple</span>
                    <div className="rounded-full w-32 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[0%] h-full rounded-full" />
                    </div>
                    <span>0</span>
                  </li>
                  <li className="text-base-davyGray text-sm flex items-center gap-2">
                    <div className="h-6 w-6 bg-base-antiFlash flex items-center justify-center">
                      <AutomationIcon
                        width={16}
                        height={16}
                        className="[&_path]:stroke-base-coolGray"
                      />
                    </div>

                    <span>Auto</span>
                    <div className="rounded-full w-32 bg-brand-lightBlue3 h-2 overflow-hidden">
                      <div className="bg-brand-baseBlue w-[0%] h-full rounded-full" />
                    </div>
                    <span>0</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 h-fit">
            <div className="flex items-center justify-between">
              <h5 className="font-bold text-sm">Sent emails</h5>
              <button className="bg-base-antiFlash p-2 rounded-lg">
                All campaigns
              </button>
            </div>
            <hr className="my-4" />
            <div className="space-y-4 flex items-center gap-4">
              <DonutChart />
            </div>
          </div>
        </div>
        <div className="bg-white p-4 h-fit">
          <div className="flex items-center justify-between">
            <h5 className="font-bold text-sm">Campaigns timeline</h5>
            <HookedForm>
              <SelectField
                className="bg-base-antiFlash p-2 rounded-lg"
                defaultValue={'monthly'}
                options={[{ label: 'Monthly', value: 'monthly' }]}
              />
            </HookedForm>
          </div>
          <hr className="my-4" />
          <div>
            <ColumnChart
              hideLegend
              data={[
                ['Months', '5-star', '4-star', '3-star', '2-star', '1-star'],
                ...(data?.timeline?.map((item: any) => [
                  new Date(item.period).toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric',
                  }),
                  item.reviews.byRating['5'] || 0,
                  item.reviews.byRating['4'] || 0,
                  item.reviews.byRating['3'] || 0,
                  item.reviews.byRating['2'] || 0,
                  item.reviews.byRating['1'] || 0,
                ]) || []),
              ]}
            />
            <ColumnChart
              inverted
              hideLegend
              data={[
                ['Period', 'Campaigns'],
                ...(data?.timeline?.map((item: any) => [
                  new Date(item.period).toLocaleDateString('en-US', {
                    month: 'short',
                    year: 'numeric',
                  }),
                  item.campaigns,
                ]) || []),
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
