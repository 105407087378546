import { Button } from '../Button/Button';
import { useForm } from 'react-hook-form';
import { HookedForm } from '../HookedForm';
import { InputField } from '../Fields/InputField';
import { AIDropdown } from '../AIDropdown/AIDropdown';
import {
  fixGrammarAndSpelling,
  improveText,
  makeTextConcise,
  makeTextFormal,
  makeTextLonger,
  regenerateText,
} from '@app/services/service';
import RegenerateIcon from '@app/assets/icons/ai/regenerate.svg?react';
import ToneIcon from '@app/assets/icons/ai/change-tone.svg?react';
import GrammerIcon from '@app/assets/icons/ai/grammar.svg?react';
import ConciseIcon from '@app/assets/icons/ai/concise.svg?react';
import LongerIcon from '@app/assets/icons/ai/text-longer.svg?react';
import FormatIcon from '@app/assets/icons/ai/format.svg?react';
import RestoreIcon from '@app/assets/icons/ai/restore.svg?react';
import GoogleIcon from '@app/assets/icons/Google.svg?react';
import FullStarIcon from '@app/assets/icons/star-full.svg?react';
import EmptyStarIcon from '@app/assets/icons/star-empty.svg?react';
import { BusinessReviews } from '@app/models/reviews';
import { getTimeAgo } from '@app/utils/utils';

export const ReviewBox = ({ review }: { review: BusinessReviews }) => {
  const reviewAnswerForm = useForm({});

  const actions = [
    {
      label: 'Improve',
      Icon: <RestoreIcon />,
      onClick: () => {
        improveText(reviewAnswerForm.getValues('answer')).then((response) => {
          reviewAnswerForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Formalize',
      Icon: <FormatIcon />,
      onClick: () => {
        makeTextFormal(reviewAnswerForm.getValues('answer')).then(
          (response) => {
            reviewAnswerForm.setValue('review', response?.data?.text);
          }
        );
      },
    },
    {
      label: 'Regenerate',
      Icon: <RegenerateIcon />,
      onClick: () => {
        regenerateText(reviewAnswerForm.getValues('answer')).then(
          (response) => {
            reviewAnswerForm.setValue('review', response?.data?.text);
          }
        );
      },
    },
    { label: 'Change tone', Icon: <ToneIcon /> },
    { label: 'Divider' },
    {
      label: 'Fix grammar & spelling',
      Icon: <GrammerIcon />,
      onClick: () => {
        fixGrammarAndSpelling(reviewAnswerForm.getValues('answer')).then(
          (response) => {
            reviewAnswerForm.setValue('review', response?.data?.text);
          }
        );
      },
    },
    {
      label: 'Make it concise',
      Icon: <ConciseIcon />,
      onClick: () => {
        makeTextConcise(reviewAnswerForm.getValues('answer')).then(
          (response) => {
            reviewAnswerForm.setValue('review', response?.data?.text);
          }
        );
      },
    },
    {
      label: 'Make it longer',
      Icon: <LongerIcon />,
      onClick: () => {
        makeTextLonger(reviewAnswerForm.getValues('answer')).then(
          (response) => {
            reviewAnswerForm.setValue('review', response?.data?.text);
          }
        );
      },
    },
  ];
  return (
    <div className="bg-white rounded-2xl p-4 space-y-2">
      <div className="flex justify-between items-center gap-4">
        <div className="w-12 h-12 bg-base-seasalt rounded-full flex items-center justify-center">
          <GoogleIcon width={30} height={30} />
        </div>
        <div className="mr-auto space-y-1">
          <h4 className="font-semibold text-sm">
            {review.firstName} {review.lastName}
            <span className="bg-system-successLight text-system-success p-1 text-xs rounded-sm ml-2">
              New
            </span>
          </h4>

          <p className="text-xs text-base-coolGray">
            Left a review {getTimeAgo(new Date(review.createdAt))}
            {review.photos.length > 0 && ` | ${review.photos.length} photos`}
          </p>
        </div>
        <Button kind="secondary" className="min-w-fit">
          Share
        </Button>
      </div>
      <div className="flex items-center gap-1">
        {[...Array(5)].map((_, index) =>
          index < review.rating ? (
            <FullStarIcon key={index} width={16} height={16} />
          ) : (
            <EmptyStarIcon key={index} width={16} height={16} />
          )
        )}
      </div>
      <div>
        <p className="text-sm">{review.review}</p>
      </div>
      <div className="flex gap-2">
        {review.photos.map((photo) => (
          <div
            key={photo.id}
            className="w-[100px] h-[100px] relative rounded-[4px] overflow-hidden"
          >
            <img
              alt="review attached"
              src={photo.url}
              className="absolute w-full h-full object-cover object-center"
            />
          </div>
        ))}
      </div>
      <div className="bg-base-seasalt p-4 border-t border-t-base-ghostWhite">
        <HookedForm formHook={reviewAnswerForm} className="flex gap-4">
          <div className="w-12 h-12 bg-base-seasalt rounded-full flex items-center justify-center">
            <GoogleIcon width={30} height={30} />
          </div>
          <div className="flex flex-col gap-4 w-full">
            <InputField className="bg-white" name="answer" />
            <div className="flex justify-between">
              <AIDropdown actions={actions} />
              <Button>Post response</Button>
            </div>
          </div>
        </HookedForm>
      </div>
    </div>
  );
};
