import { HookedForm } from '../HookedForm';
import { CustomerInterface } from '@app/models/business';
import { useGenericTable } from './GenericTable.hooks';
import { Pagination } from '../Pagination/Pagination';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import ScheduleCampaingIcon from '@app/assets/icons/schedule-campaign.svg?react';
import AddToListIcon from '@app/assets/icons/add-list.svg?react';
import DeleteIcon from '@app/assets/icons/bin.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';
import { ReactNode } from 'react';

export const ListPill = ({ name }: { name: string }) => {
  return (
    <span className="bg-base-antiFlash py-1 px-1.5 text-xs text-base-davyGray inline-flex gap-2 items-center rounded-sm">
      <FolderIcon />
      <span>{name}</span>
    </span>
  );
};

export const TagPill = ({ name }: { name: string }) => {
  return (
    <span className="bg-base-antiFlash py-1 px-1.5 text-xs text-base-davyGray inline-flex gap-2 items-center rounded-sm">
      {name}
    </span>
  );
};
interface GenericTableProps<T> {
  data: T[];
  total: number;
  headers: { label: string; field: string }[];
  renderRow: (data: T[]) => ReactNode;
  handleNextPage: (page?: number) => void;
  handlePrevPage: (page?: number) => void;
  handleLimitChange: (limit?: number) => void;
  handleScheduleCampaing?: () => void;
  handleAddToList?: (customer?: CustomerInterface) => void;
  handleDeleteCustomer?: () => void;
  externalForm?: UseFormReturn<FieldValues, any, undefined>;
  currentPage?: string | number;
  isLoading?: boolean;
}

export const GenericTable = <T extends unknown>({
  data,
  total,
  headers,
  renderRow,
  handleNextPage,
  handlePrevPage,
  handleLimitChange,
  externalForm,
  currentPage,
  handleScheduleCampaing,
  handleAddToList,
  handleDeleteCustomer,
  isLoading,
}: GenericTableProps<T>) => {
  const { genericTableForm } = useGenericTable();

  const hasSelected =
    externalForm
      ?.watch('selected')
      ?.some((item: { value: boolean | undefined }) => item.value) ||
    genericTableForm
      .watch('selected')
      ?.some((item: { value: boolean | undefined }) => item.value);

  return (
    <>
      <div className="bg-white w-full">
        {isLoading && <p className="p-4 font-bold">Loading data...</p>}
        {!isLoading && data?.length === 0 && (
          <p className="p-4 font-bold">No data found</p>
        )}
        {!isLoading && data?.length > 0 && (
          <HookedForm
            formHook={externalForm ?? genericTableForm}
            mode="onChange"
          >
            <table className="w-full">
              <thead>
                <tr className="border-b border-base-antiFlash">
                  {!hasSelected &&
                    headers.map((item, index) => (
                      <th
                        key={index}
                        className="p-4 text-xs font-medium text-left text-base-coolGray uppercase"
                      >
                        {item.label}
                      </th>
                    ))}
                  {hasSelected && (
                    <th
                      colSpan={5}
                      className="text-sm text-brand-baseBlue font-medium"
                      align="left"
                    >
                      <div className="flex gap-4 ">
                        <button
                          {...(handleScheduleCampaing && {
                            onClick: handleScheduleCampaing,
                          })}
                          className="flex items-center gap-1"
                        >
                          <ScheduleCampaingIcon /> Schedule new campaing
                        </button>
                        <button
                          {...(handleAddToList && {
                            onClick: () => handleAddToList(),
                          })}
                          className="flex items-center gap-1"
                        >
                          <AddToListIcon /> Add to list(s)
                        </button>
                        <button
                          {...(handleDeleteCustomer && {
                            onClick: () => handleDeleteCustomer(),
                          })}
                          className="flex items-center gap-1"
                        >
                          <DeleteIcon /> Delete customer(s)
                        </button>
                      </div>
                    </th>
                  )}
                  <th></th>
                </tr>
              </thead>
              <tbody>{renderRow(data)}</tbody>
            </table>
            <Pagination
              page={Number(currentPage)}
              total={total}
              handleLimitChange={handleLimitChange}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
            />
          </HookedForm>
        )}
      </div>
    </>
  );
};
