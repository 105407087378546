import { useListViewPage } from './ListView.hooks';
import {
  BaseDialog,
  Button,
  CheckSelectField,
  CustomerTable,
  HookedForm,
  InputField,
  Tabs,
  TextField,
} from '@app/components';
import { CustomerTabs } from '@app/utils/consts';
import CloseIcon from '@app/assets/icons/close.svg?react';
import ArrowBackIcon from '@app/assets/icons/back.svg?react';
import PencilIcon from '@app/assets/icons/pencil.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';
import { CustomerInterface } from '@app/models/business';
import { OptionInterface } from '@app/models/util';

export const ListView = () => {
  const {
    navigate,
    listData,
    listCustomers,
    isLoadingCustomers,
    handleTabChange,
    handleListModal,
    handleListUpdate,
    handleNextPage,
    handlePrevPage,
    handleLimit,
    listUpdateForm,
    listUpdateModal,
    isPending,
    customers,
    listCustomerQuery,
    refetchCustomers,
  } = useListViewPage();

  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto">
            <div className="flex justify-between items-center p-4">
              <h2 className="font-bold text-[28px]">
                <button
                  onClick={() => navigate('/customers/lists')}
                  className="mr-2"
                >
                  <ArrowBackIcon />
                </button>
                {listData?.data?.list?.name}
              </h2>
            </div>
            <div className="px-4">
              <Tabs
                tabs={CustomerTabs as unknown as OptionInterface[]}
                onClick={handleTabChange}
              />
            </div>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-8 gap-4">
            <div className="bg-white p-4 h-fit col-span-1 md:col-span-3">
              <div className="flex items-center justify-between">
                <h5 className="font-bold text-sm">List Information</h5>
                <button
                  onClick={handleListModal}
                  className="bg-base-antiFlash p-2 rounded-lg"
                >
                  <PencilIcon />
                </button>
              </div>
              <hr className="my-4" />
              <div className="space-y-4">
                <table>
                  <tbody>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          LIST ID
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {listData?.data?.list?.id}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          LIST NAME
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {listData?.data?.list?.name}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr className="my-6" />
                <div>
                  <h5 className="text-xs text-base-coolGray mb-2">NOTES</h5>
                  <p className="text-base-coolGray text-sm">
                    {listData?.data?.list?.notes}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 col-span-1 md:col-span-5 h-fit">
              {isLoadingCustomers && <div>Loading customers...</div>}
              {!isLoadingCustomers && (
                <CustomerTable
                  handleSortingTable={() => {}}
                  simplified
                  currentPage={listCustomerQuery.page}
                  customers={listCustomers?.data?.customers ?? []}
                  handleNextPage={handleNextPage}
                  handleLimitChange={handleLimit}
                  handlePrevPage={handlePrevPage}
                  refetch={refetchCustomers}
                  totalCustomers={listCustomers?.data?.count ?? 0}
                  handleCustomerSelection={() => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <BaseDialog
        size="max-w-2xl"
        open={listUpdateModal}
        onClose={handleListModal}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <FolderIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Update List</h5>
            <p className="text-base-coolGray text-sm">
              Fill in the fields below and updated selected list
            </p>
          </div>
          <div>
            <button type="button" onClick={handleListModal}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={listUpdateForm}
            onSubmit={handleListUpdate}
            className="space-y-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <InputField name="name" label="List" />

              <CheckSelectField
                name="customers"
                label="Add to customer(s)"
                isOptional
                showPill
                options={
                  customers?.data?.customers?.map(
                    (customer: CustomerInterface) => ({
                      label: `${customer.firstName} ${customer.lastName}`,
                      value: customer.id,
                    })
                  ) ?? []
                }
              />
            </div>
            <TextField name="notes" label="Notes" isOptional />
            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                type="button"
                onClick={handleListModal}
              >
                Cancel
              </Button>
              <Button
                disabled={isPending}
                loading={isPending}
                className="w-full"
                type="submit"
              >
                Update List
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
    </>
  );
};
