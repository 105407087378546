import { CampaignInterface } from '@app/models/campaigns';
import { getCampaigns } from '@app/services/campaigns';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useCampaigns = () => {
  const navigate = useNavigate();
  const [createSecheduleModal, setCreateScheduleModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState<string | undefined>();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<string | undefined>();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const filterForm = useForm();

  const {
    data: campaignsData,
    isLoading,
    refetch,
  } = useQuery<{ campaigns: CampaignInterface[]; count: number }>({
    queryKey: ['all-lists', page, limit, sortBy, sortOrder],
    queryFn: () => {
      const { statuses, channels, platforms, startDate, endDate } =
        filterForm.getValues();
      return getCampaigns({
        statuses,
        channels,
        platforms,
        from: startDate,
        to: endDate,
        page,
        limit,
        sortBy,
        sortOrder,
      })
        .then((res) => res?.data)
        .catch((err) => {
          console.error('Error fetching campaigns:', err);
          // Return a valid data structure instead of the error
          return { campaigns: [], count: 0 };
        });
    },
  });

  const handleDeleteModal = (id?: string) => {
    setDeleteModal(id);
  };

  const handleCreateScheduleModal = () => {
    setCreateScheduleModal((prev) => !prev);
  };

  const createCampaign = () => {
    navigate('/campaigns/create/settings');
  };

  const createAutomation = () => {
    navigate('/campaigns/automation');
  };

  const handleLimitChange = (newLimit: number | undefined) => {
    setLimit(newLimit ?? 10);
    setPage(1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setPage((prev) => Math.max(prev - 1, 1));
  };

  const handleSortingTable = (field: string) => {
    if (sortBy === field) {
      setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  useEffect(() => {
    refetch();
  }, [filterForm.watch()]);
  return {
    handleCreateScheduleModal,
    createSecheduleModal,
    campaignsData,
    isLoading,
    refetch,
    createAutomation,
    createCampaign,
    handleDeleteModal,
    deleteModal,
    // Return pagination values and handlers
    page,
    limit,
    sortBy,
    sortOrder,
    handleLimitChange,
    handleNextPage,
    handlePreviousPage,
    handleSortingTable,
    filterForm,
  };
};
