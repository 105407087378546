import { fetchBusiness } from '@app/redux/features/business';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { createBusiness, getCategories } from '@app/services/business';
import { newBusinessValidation } from '@app/utils/validations';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useNewBusinessPage = () => {
  const businessState = useAppSelector((state) => state.business);
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newBusinessForm = useForm({ resolver: newBusinessValidation });
  const [error, setError] = useState<string | undefined>();

  const { data: businessCategories = [], isLoading } = useQuery({
    queryKey: ['businessCategories'],
    queryFn: () => getCategories(),
  });

  const handleSubmit = (values: FieldValues) => {
    createBusiness({
      ...(values as {
        name: string;
        address: string;
        category: string;
      }),
      userId: authState.user?.id as string,
    })
      .then(() => {
        dispatch(fetchBusiness());
        navigate('/select-business');
      })
      .catch(() => {
        setError('Error while creating your business.');
      });
  };
  return {
    newBusinessForm,
    handleSubmit,
    businessState,
    error,
    isLoading,
    businessCategories,
  };
};
