import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';
import { HookedForm } from '../HookedForm';
import { CheckSelectField } from '../Fields/CheckSelectField';
import { DateField } from '../Fields/DateField';
import ArrowRightIcon from '@app/assets/icons/arrow-right.svg?react';

export const CampaignsFilters = ({
  filterForm,
}: {
  filterForm?: UseFormReturn<FieldValues>;
}) => {
  const formHook = useForm();
  return (
    <div>
      <HookedForm
        formHook={filterForm ?? formHook}
        mode="onChange"
        className="flex gap-4 flex-col md:flex-row items-center"
      >
        <DateField sizeStyle="sm" name="startDate" />
        <div>
          <ArrowRightIcon className="rotate-90 md:rotate-0" />
        </div>
        <DateField sizeStyle="sm" name="endDate" />
        <CheckSelectField
          name="channels"
          options={[
            { label: 'All channels', value: 'all' },
            { label: 'Email', value: 'email' },
            { label: 'Text', value: 'text' },
          ]}
          placeholder="Channels"
          sizeStyle="sm"
        />

        <CheckSelectField
          name="statuses"
          options={[
            { label: 'All statuses', value: 'all' },
            { label: 'Scheduled', value: 'scheduled' },
            { label: 'Active', value: 'active' },
            { label: 'Completed', value: 'completed' },
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Drafts', value: 'drafts' },
          ]}
          placeholder="Statuses"
          sizeStyle="sm"
        />
        <CheckSelectField
          name="platforms"
          options={[
            { label: 'All platforms', value: 'all' },
            { label: 'Google', value: 'google' },
            { label: 'Facebook', value: 'facebook' },
            { label: 'Yelp', value: 'yelp' },
            { label: 'Trustpilot', value: 'trustpilot' },
          ]}
          placeholder="Platforms"
          sizeStyle="sm"
        />
      </HookedForm>
    </div>
  );
};
