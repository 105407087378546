import { FieldValues, useForm } from 'react-hook-form';
import RegenerateIcon from '@app/assets/icons/ai/regenerate.svg?react';
import ToneIcon from '@app/assets/icons/ai/change-tone.svg?react';
import GrammerIcon from '@app/assets/icons/ai/grammar.svg?react';
import ConciseIcon from '@app/assets/icons/ai/concise.svg?react';
import LongerIcon from '@app/assets/icons/ai/text-longer.svg?react';
import FormatIcon from '@app/assets/icons/ai/format.svg?react';
import RestoreIcon from '@app/assets/icons/ai/restore.svg?react';
import {
  fixGrammarAndSpelling,
  improveText,
  makeTextConcise,
  makeTextFormal,
  makeTextLonger,
  regenerateText,
} from '@app/services/service';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getRequestById } from '@app/services/ratings';
import { createReview } from '@app/services/reviews';

export const useLeaveReview = () => {
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const navigate = useNavigate();
  const leaveReviewForm = useForm();
  const params = useParams();
  const requestId = params.requestId as string;

  const { data: requestData } = useQuery({
    queryKey: ['request-data'],
    queryFn: () => {
      return getRequestById(requestId);
    },
  });

  const handleImageThumbnail = (file: File) => {
    if (uploadedImages.some((image) => image.name === file.name)) {
      return;
    }
    setUploadedImages([...uploadedImages, file]);
    setThumbnails([...thumbnails, URL.createObjectURL(file)]);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles || !selectedFiles?.length) {
      return;
    }

    Array.from(selectedFiles).forEach((file) => {
      handleImageThumbnail(file);
    });
  };

  const removeFile = (index: number) => {
    const newImages = [...uploadedImages];
    const newThumbnails = [...thumbnails];

    URL.revokeObjectURL(newThumbnails[index]);

    newImages.splice(index, 1);
    newThumbnails.splice(index, 1);

    setUploadedImages(newImages);
    setThumbnails(newThumbnails);
  };

  const handleStarClick = (star: number) => {
    leaveReviewForm.setValue('rating', star + 1);
  };

  const ratingValue = leaveReviewForm.watch('rating') ?? 0;
  const reviewValue = leaveReviewForm.watch('review') ?? '';

  const actions = [
    {
      label: 'Improve',
      Icon: <RestoreIcon />,
      onClick: () => {
        improveText(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Formalize',
      Icon: <FormatIcon />,
      onClick: () => {
        makeTextFormal(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Regenerate',
      Icon: <RegenerateIcon />,
      onClick: () => {
        regenerateText(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Change tone',
      Icon: <ToneIcon />,
      children: [
        {
          label: 'Regenerate',
          Icon: <RegenerateIcon />,
          onClick: () => {
            regenerateText(reviewValue).then((response) => {
              leaveReviewForm.setValue('review', response?.data?.text);
            });
          },
        },
        {
          label: 'Regenerate',
          Icon: <RegenerateIcon />,
          onClick: () => {
            regenerateText(reviewValue).then((response) => {
              leaveReviewForm.setValue('review', response?.data?.text);
            });
          },
        },
      ],
    },
    { label: 'Divider' },
    {
      label: 'Fix grammar & spelling',
      Icon: <GrammerIcon />,
      onClick: () => {
        fixGrammarAndSpelling(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Make it concise',
      Icon: <ConciseIcon />,
      onClick: () => {
        makeTextConcise(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
    {
      label: 'Make it longer',
      Icon: <LongerIcon />,
      onClick: () => {
        makeTextLonger(reviewValue).then((response) => {
          leaveReviewForm.setValue('review', response?.data?.text);
        });
      },
    },
  ];

  const handleSubmit = (values: FieldValues) => {
    if (!values.rating) {
      return;
    }

    createReview({
      rating: String(values.rating),
      comment: values.review,
      images: uploadedImages,
      requestId,
    }).then((res) => {
      if (res?.data?.rating >= 3) {
        navigate(`/leave-review/${requestId}/thank-you`);
      } else {
        navigate(`/leave-review/${requestId}/need-improvement`);
      }
      leaveReviewForm.reset();
    });
  };

  const handleBadReason = (reason: string) => {
    const currentReasons = leaveReviewForm.watch('reason') ?? [];
    if (currentReasons.includes(reason)) {
      leaveReviewForm.setValue(
        'reason',
        currentReasons.filter((r: string) => r !== reason)
      );
    } else {
      leaveReviewForm.setValue('reason', [...currentReasons, reason]);
    }
  };

  useEffect(() => {
    return () => {
      thumbnails.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [thumbnails]);

  return {
    leaveReviewForm,
    handleStarClick,
    ratingValue,
    reviewValue,
    actions,
    handleChange,
    thumbnails,
    removeFile,
    handleSubmit,
    handleBadReason,
    requestData,
  };
};
