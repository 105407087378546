import { CustomerInterface } from '@app/models/business';
import { getPaginatedCustomers } from '@app/services/customers';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { updateFormData, resetForm } from '@app/redux/features/campaignForm';

export const useCreateCampaign = (form: UseFormReturn) => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state) => state.campaignForm.formData);

  const { data: customers, isLoading } = useQuery({
    queryKey: ['customers'],
    queryFn: () => getPaginatedCustomers(),
  });

  // Load saved form data when the component mounts
  useEffect(() => {
    // Only set values that exist in formData and aren't already set in the form
    if (formData) {
      Object.keys(formData).forEach((key) => {
        const currentValue = form.getValues(key);
        if (formData[key] !== undefined && !currentValue) {
          form.setValue(key, formData[key], { shouldDirty: true });
        }
      });
    }
  }, [form, formData]);

  // Save form data when it changes
  const saveFormData = useCallback(() => {
    const values = form.getValues();
    dispatch(updateFormData(values));
  }, [form, dispatch]);

  // Add a function to completely reset the form
  const resetCampaignForm = useCallback(() => {
    form.reset();
    dispatch(resetForm());
    localStorage.removeItem('campaignFormData');
  }, [form, dispatch]);

  const handleCustomersModal = () => {
    // Save current form state before opening/closing modal
    saveFormData();
    setShowCustomerModal(!showCustomerModal);
  };

  const handleCustomerSelection = useCallback(
    (customerId?: string) => {
      if (!customerId) return;
      const selectedCustomers = form.watch('selectedCustomers') ?? [];

      if (customerId === 'all') {
        const allPageCustomers = customers?.data?.customers ?? [];
        const customersId = allPageCustomers.map(
          (cus: CustomerInterface) => cus.id
        );

        const selectedId = selectedCustomers.map(
          (cus: CustomerInterface) => cus.id
        );

        if (customersId.every((id: string) => selectedId.includes(id))) {
          form.setValue('selectedCustomers', []);
          saveFormData();
          return;
        } else {
          const newSelected = [
            ...selectedCustomers,
            ...(customers?.data?.customers?.filter(
              (cus: CustomerInterface) => !selectedId.includes(cus.id)
            ) ?? []),
          ];

          form.setValue('selectedCustomers', newSelected);
          saveFormData();
        }
        return;
      }

      if (
        selectedCustomers.some(
          (cus: CustomerInterface) => cus.id === customerId
        )
      ) {
        const updatedList = selectedCustomers.filter(
          (cus: CustomerInterface) => cus.id !== customerId
        );

        form.setValue('selectedCustomers', updatedList);
        saveFormData();
      } else {
        const customer = customers?.data?.customers?.find(
          (cus: CustomerInterface) => cus.id === customerId
        );
        if (customer) {
          const updatedList = [...selectedCustomers, customer];

          form.setValue('selectedCustomers', updatedList);
          saveFormData();
        }
      }
    },
    [customers, form, saveFormData]
  );

  return {
    customers,
    isLoading,
    handleCustomersModal,
    showCustomerModal,
    handleCustomerSelection,
    saveFormData,
    resetCampaignForm, // Export the reset function
  };
};
