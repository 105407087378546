import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const generateText = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/generate`, { text });
};

export const improveText = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/improve`, { text });
};

export const makeTextFormal = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/formal`, { text });
};

export const fixGrammarAndSpelling = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/fix`, { text });
};

export const makeTextConcise = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/concise`, { text });
};

export const makeTextLonger = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/longer`, { text });
};

export const changeTone = async (text: string, tone: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/tone`, { text, tone });
};

export const regenerateText = async (text: string) => {
  return apiInstance.post(`${ApiRoutes.Service}/regenerate`, { text });
};
