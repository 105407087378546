import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { useEffect, useState } from 'react';
import {
  fetchBusinessCategories,
  selectBusiness,
} from '@app/redux/features/business';
import { getUserWithBusinesses } from '@app/services/user';
import { ActionTypes } from '@app/redux/types';
import { toast } from 'react-toastify';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5,
};

export const AnimationLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const [isConnected, setIsConnected] = useState(true);
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);
  const location = useLocation();

  const excludePath = matchRoutes(
    [
      { path: 'forgot-password' },
      { path: 'reset-password/:token' },
      { path: '/validate-email/:token' },
      { path: '/leave-review/:requestId' },
      { path: '/leave-review/:requestId/thank-you' },
      { path: '/leave-review/:requestId/need-improvement' },
    ],
    location.pathname
  );

  useEffect(() => {
    const hasAccessToken = localStorage.getItem('accessToken');

    if (hasAccessToken && !auth.user) {
      getUserWithBusinesses()
        .then((res) => {
          dispatch({ type: ActionTypes.AUTH_SAVE, payload: res?.data?.user });
          dispatch({
            type: ActionTypes.BUSINESS_LOAD_LIST,
            payload: res?.data?.businesses,
          });

          if (excludePath) {
            return;
          }

          if (res?.data?.businesses) {
            if (res?.data?.businesses?.length > 1) {
              navigate('/select-business');
            }

            if (res?.data?.businesses?.length === 1) {
              dispatch(selectBusiness({ id: res?.data?.businesses[0].id }));
              navigate('/');
            }
          }
        })
        .catch(() => {
          localStorage.removeItem('accessToken');
        });
    }

    if (hasAccessToken && auth.user) {
      navigate('/');
    }

    window.addEventListener('load', () => {
      const handleNetworkChange = () => {
        if (navigator.onLine) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
      };

      window.addEventListener('online', handleNetworkChange);
      window.addEventListener('offline', handleNetworkChange);
    });
  }, []);

  useEffect(() => {
    if (!isConnected) {
      toast.warn('You are offline');
    }
  }, [isConnected]);

  useEffect(() => {
    if (business.categories?.length === 0 && !business.isCategoriesFetched) {
      dispatch(fetchBusinessCategories());
    }
    if (business.selected && !business.selected.id) {
      navigate('/select-business');
    }
  }, [business]);

  return (
    <motion.div
      key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Outlet />
    </motion.div>
  );
};
