import {
  BaseDialog,
  Button,
  ContactFilters,
  CustomerTable,
  HookedForm,
  InputField,
} from '@app/components';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useCreateCampaign } from './CreateCampaign.hooks';
import UserIcon from '@app/assets/icons/user.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/redux/hooks';
import { updateFormData } from '@app/redux/features/campaignForm';
import { CustomerInterface } from '@app/models/business';

export const CreateCampaignCustomers = ({
  form,
}: {
  form: UseFormReturn<FieldValues, any, undefined>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    customers,
    isLoading,
    handleCustomersModal,
    showCustomerModal,
    saveFormData,
  } = useCreateCampaign(form);

  // Ensure the 'selectedCustomers' field exists in the form
  useEffect(() => {
    // Check if we have data in localStorage
    const storedFormData = localStorage.getItem('campaignFormData');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      // Update form with localStorage data if it exists
      Object.keys(parsedData).forEach((key) => {
        if (parsedData[key] !== undefined) {
          form.setValue(key, parsedData[key]);
        }
      });
    }

    // Still ensure selectedCustomers exists
    const selectedCustomers = form.getValues('selectedCustomers');
    if (!selectedCustomers) {
      form.setValue('selectedCustomers', []);
    }
  }, [form]);

  // Toggle customer selection in the form state
  const toggleCustomerSelection = (customerId: string) => {
    const currentSelected = form.getValues('selectedCustomers') || [];

    if (currentSelected.includes(customerId)) {
      form.setValue(
        'selectedCustomers',
        currentSelected.filter((id: string) => id !== customerId),
        { shouldDirty: true }
      );
    } else {
      form.setValue('selectedCustomers', [...currentSelected, customerId], {
        shouldDirty: true,
      });
    }

    // Save after selection changes
    saveFormData();
  };

  // Get the count of selected customers
  const getSelectedCustomersCount = () => {
    const selected = form.getValues('selectedCustomers') || [];
    return selected.length;
  };

  const handleNext = () => {
    // Save form data before proceeding to next step
    const values = form.getValues();
    dispatch(updateFormData(values));
    // Also save to localStorage
    localStorage.setItem('campaignFormData', JSON.stringify(values));
    navigate('/campaigns/create/campaign');
  };

  const handleBack = () => {
    // Save form data before going back
    const values = form.getValues();
    dispatch(updateFormData(values));
    // Also save to localStorage
    localStorage.setItem('campaignFormData', JSON.stringify(values));
    navigate('/campaigns/create/settings');
  };

  return (
    <>
      <div className="container mx-auto p-4 flex flex-col justify-between items-center gap-4">
        <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
          <div>
            <h4 className="text-sm font-semibold">Send to customers</h4>
            <p className="text-xs text-base-coolGray">
              Filter the customers to whom you want to send this request. If
              nothing is selected, the request will be sent to all
            </p>
          </div>
          <hr />
          <div className="flex gap-2 items-center justify-center">
            <Button
              kind="secondary"
              className="min-w-fit"
              onClick={() => handleCustomersModal()}
            >
              Select customers
            </Button>
            <Button disabled kind="secondary" className="min-w-fit">
              Select lists
            </Button>
            <Button disabled kind="secondary" className="min-w-fit">
              Import from file
            </Button>
            <Button disabled kind="secondary" className="min-w-fit">
              Create customer
            </Button>
          </div>
          <div>
            <h4 className="font-bold">
              {getSelectedCustomersCount()} customers selected
            </h4>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {customers?.data?.customers &&
            form.getValues('selectedCustomers')?.length > 0 ? (
              <div className="space-y-2">
                {customers.data.customers
                  .filter((customer: CustomerInterface) =>
                    form.getValues('selectedCustomers')?.includes(customer.id)
                  )
                  .map((customer: CustomerInterface) => (
                    <div
                      key={customer.id}
                      className="border-b border-b-base-ghostWhite p-3 rounded flex justify-between items-center"
                    >
                      <div>
                        <p className="font-medium">
                          {customer.firstName} {customer.lastName}
                        </p>
                        <p className="text-sm text-base-coolGray">
                          {customer.email}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={() => toggleCustomerSelection(customer.id)}
                        className="text-base-coolGray hover:text-base-night"
                      >
                        <CloseIcon width={16} height={16} />
                      </button>
                    </div>
                  ))}
              </div>
            ) : (
              <p className="text-base-coolGray text-sm">
                No customers selected
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-between mt-4 w-full max-w-[800px]">
          <Button kind="secondary" type="button" onClick={handleBack}>
            Back: Campaign Settings
          </Button>
          <Button type="button" onClick={handleNext}>
            Next: Campaign Details
          </Button>
        </div>
      </div>

      <BaseDialog
        size="max-w-6xl"
        open={showCustomerModal}
        onClose={handleCustomersModal}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg">Select customers</h5>
          </div>
          <div>
            <button type="button" onClick={() => handleCustomersModal()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4 max-h-[500px] overflow-y-auto">
          <div className="container mx-auto px-4 py-2">
            <ContactFilters filterForm={form} lists={[]} tags={[]} />
          </div>

          {!isLoading && (
            <div className="bg-white">
              <div className="container mx-auto py-6 px-4 flex flex-col md:flex-row justify-between items-end md:items-center gap-4">
                <h3 className="text-2xl font-bold">
                  {customers?.data?.count} contacts (
                  {getSelectedCustomersCount()} selected)
                </h3>
                <div>
                  <HookedForm formHook={form}>
                    <InputField name="search" placeholder="Search" />
                  </HookedForm>
                </div>
              </div>
            </div>
          )}
          <CustomerTable
            simplified
            customers={customers?.data?.customers ?? []}
            totalCustomers={0}
            externalForm={form}
            handleNextPage={function (): void {
              throw new Error('Function not implemented.');
            }}
            handlePrevPage={function (): void {
              throw new Error('Function not implemented.');
            }}
            handleLimitChange={function (): void {
              throw new Error('Function not implemented.');
            }}
            handleSortingTable={function (): void {
              throw new Error('Function not implemented.');
            }}
            handleCustomerSelection={(customerId?: string) => {
              if (customerId) {
                toggleCustomerSelection(customerId);
              }
            }}
          />
        </div>
        <div className="p-6">
          <div className="flex items-center gap-4 justify-center">
            <Button
              kind="secondary"
              className="w-full"
              type="button"
              onClick={() => handleCustomersModal()}
            >
              Cancel
            </Button>
            <Button
              kind="danger"
              className="w-full"
              type="button"
              onClick={handleCustomersModal}
            >
              Add {getSelectedCustomersCount()} selected customers
            </Button>
          </div>
        </div>
      </BaseDialog>
    </>
  );
};
