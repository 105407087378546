import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Button } from '../Button/Button';
import { ReactNode } from 'react';
import AIFullIcon from '@app/assets/icons/AI-full.svg?react';

interface AIDropdownProps {
  actions: {
    label: string;
    Icon?: ReactNode;
    onClick?: () => void;
    children?: { label: string; Icon?: ReactNode; onClick?: () => void }[];
  }[];
}

export const AIDropdown = ({ actions }: AIDropdownProps) => {
  return (
    <Menu>
      <MenuButton className="group/user flex items-center justify-center gap-2">
        <Button
          type="button"
          leftNode={<AIFullIcon />}
          kind="secondary"
          className="text-system-AI font-medium "
        >
          Improve with AI
        </Button>
      </MenuButton>
      <MenuItems
        transition
        anchor="top"
        className="p-2 bg-white shadow-lg min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 rounded-lg border border-base-seasalt"
      >
        {actions.map((action, index) => {
          if (action.label === 'Divider') {
            return (
              <MenuItem key={index}>
                <hr className="border-base-antiFlash" />
              </MenuItem>
            );
          }
          const Icon = action?.Icon ?? null;
          return (
            <MenuItem key={index}>
              <button
                {...(action.onClick && { onClick: action.onClick })}
                className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
              >
                {Icon && Icon} {action.label}
              </button>
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};
