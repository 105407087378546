import { Button } from '../Button/Button';
import ArrowLeft from '@app/assets/icons/arrow-left.svg?react';
import ArrowRight from '@app/assets/icons/arrow-right.svg?react';
import { SelectField } from '../Fields/SelectField';
import { InputField } from '../Fields/InputField';
import { useState } from 'react';

export const Pagination = ({
  page = 1,
  total = 0,
  handleNextPage,
  handlePrevPage,
  handleLimitChange,
}: {
  page?: number;
  total?: number;
  handleNextPage: (page: number) => void;
  handlePrevPage: (page: number) => void;
  handleLimitChange: (limit: number) => void;
}) => {
  const [limit, setLimit] = useState('10');
  const numberOfPages = Math.ceil(total / Number(limit));

  return (
    <div className="flex justify-between px-4 py-2">
      <div className="flex gap-2 items-center flex-col md:flex-row">
        <span className="text-base-davyGray text-sm">Page</span>
        <InputField
          containerClass="!w-[75px]"
          className="min-w-fit w-fit"
          type="number"
          name="page"
          min={1}
          max={numberOfPages}
          defaultValue={page}
          externalHandler={(e) => {
            if (
              e.target?.value &&
              Number(e.target?.value) <= numberOfPages &&
              Number(e.target?.value) > 0
            ) {
              if (Number(e.target.value) > page) {
                handleNextPage(Number(e.target.value));
              } else {
                handlePrevPage(Number(e.target.value));
              }
            }
          }}
        />
        <span className="text-base-davyGray text-sm">of {numberOfPages}</span>
        <Button
          kind="quaternary"
          className="min-w-fit"
          disabled={page === 1}
          leftNode={<ArrowLeft className="-mr-2" />}
          onClick={() => handlePrevPage(page - 1 === 0 ? 1 : page - 1)}
        />
        <Button
          kind="quaternary"
          onClick={() =>
            handleNextPage(page + 1 > numberOfPages ? page : page + 1)
          }
          disabled={page === numberOfPages}
          type="button"
          rightNode={<ArrowRight />}
        >
          Next page
        </Button>
      </div>
      <div className="flex items-center gap-2 text-base-davyGray flex-col md:flex-row">
        <span className="text-sm">Show</span>
        <SelectField
          onSelect={(e) => {
            handleLimitChange(Number(e as unknown as string));
            setLimit(() => e as unknown as string);
          }}
          onChange={(e) => {
            handleLimitChange(Number(e.currentTarget.value));
          }}
          name="limit"
          defaultValue={String(limit)}
          options={[
            { label: '10', value: '10' },
            { label: '20', value: '20' },
            { label: '50', value: '50' },
            { label: '100', value: '100' },
          ]}
        />
        <span className="flex-shrink-0 text-sm">per page</span>
      </div>
    </div>
  );
};
