import { useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { createCampaign } from '@app/services/campaigns';
import {
  Button,
  DateField,
  HookedForm,
  InputField,
  SelectField,
} from '@app/components';
import { SwitchField } from '@app/components/Fields/SwitchField';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@app/assets/icons/add.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { useAppDispatch, useAppSelector } from '@app/redux/hooks';
import { resetForm, updateFormData } from '@app/redux/features/campaignForm';

export const CreateCampaignCampaign = ({
  form,
}: {
  form: UseFormReturn<FieldValues, unknown, undefined>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getValues, handleSubmit } = form;
  const [followUpRows, setFollowUpRows] = useState([0]); // Start with one row
  const formData = useAppSelector((state) => state.campaignForm.formData);

  // Initialize form with saved data for this step
  useEffect(() => {
    // First check localStorage for saved form data
    const storedFormData = localStorage.getItem('campaignFormData');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      Object.keys(parsedData).forEach((key) => {
        if (parsedData[key] !== undefined) {
          form.setValue(key, parsedData[key]);
        }
      });

      // If follow-up rows were previously set, restore them
      const savedFollowUpDays1 = parsedData.followUpDays1;
      const savedFollowUpDays2 = parsedData.followUpDays2;

      if (savedFollowUpDays1) {
        setFollowUpRows([0, 1]);
      }
      if (savedFollowUpDays2) {
        setFollowUpRows([0, 1, 2]);
      }
    }
    // Then check Redux state if localStorage didn't have data
    else if (formData) {
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== undefined) {
          form.setValue(key, formData[key]);
        }
      });

      // If follow-up rows were previously set, restore them
      const savedFollowUpDays1 = formData.followUpDays1;
      const savedFollowUpDays2 = formData.followUpDays2;

      if (savedFollowUpDays1) {
        setFollowUpRows([0, 1]);
      }
      if (savedFollowUpDays2) {
        setFollowUpRows([0, 1, 2]);
      }
    }
  }, [form, formData]);

  const addFollowUpRow = () => {
    if (followUpRows.length < 3) {
      setFollowUpRows([...followUpRows, followUpRows.length]);
    }
  };

  const removeFollowUpRow = (index: number) => {
    setFollowUpRows(followUpRows.filter((_, i) => i !== index));

    // Clear the form values for the removed row
    const rowNumber = followUpRows[index];
    form.setValue(`followUpDays${rowNumber}`, undefined);
    form.setValue(`followUpPeriod${rowNumber}`, undefined);

    // Save the updated form data
    const values = form.getValues();
    dispatch(updateFormData(values));
    localStorage.setItem('campaignFormData', JSON.stringify(values));
  };

  const handleBack = () => {
    // Save form data before going back
    const values = form.getValues();
    dispatch(updateFormData(values));
    localStorage.setItem('campaignFormData', JSON.stringify(values));
    navigate('/campaigns/create/customers');
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      // Get all form values from all steps
      const formData = getValues();
      console.log(formData);
      // Save one last time before submission
      dispatch(updateFormData(formData));
      localStorage.setItem('campaignFormData', JSON.stringify(formData));
      console.log(formData.initialSendDate);
      await createCampaign({
        name: formData.campaignName,
        description: formData.description,
        type: formData.type || 'standard',
        customers: formData.selectedCustomers || [],
        sendImmediately: formData.sendImmediately,
        startDate: formData.sendImmediately
          ? new Date().toISOString()
          : formData.initialSendDate,
        schedule: {
          startDate: formData.initialSendDate
            ? new Date(formData.initialSendDate).toLocaleDateString()
            : new Date().toLocaleDateString(),
          time: formData.initialSendDate
            ? new Date(formData.initialSendDate).toLocaleTimeString()
            : undefined,
        },
        template: {
          emailTemplate: formData.emailTemplate,
          textTemplate: formData.textTemplate,

          ...(formData.subject && { subject: formData.subject }),
          ...(formData.body && { body: formData.body }),
        },
      });

      toast.success('Campaign created successfully');

      // Clear form state
      form.reset();

      // Reset form data in Redux
      dispatch(resetForm());

      // Clear localStorage completely
      localStorage.removeItem('campaignFormData');

      // Navigate back to campaigns list
      navigate('/campaigns');
    } catch (error) {
      console.error('Error creating campaign:', error);
      toast.error('Failed to create campaign');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-4 flex flex-col justify-between items-center gap-4">
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Initial sending</h4>
          <p className="text-xs text-base-coolGray">
            Set a date for your first send of this campaign
          </p>
        </div>
        <hr />
        <HookedForm formHook={form} className="space-y-4">
          <SwitchField
            name="sendImmediately"
            label={<span>Send immediately</span>}
          />
          <DateField
            name="initialSendDate"
            label="When should we send the initial campaign? "
          />
        </HookedForm>
      </div>
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Design templates</h4>
          <p className="text-xs text-base-coolGray">
            Choose a design template for windows or create a new one
          </p>
        </div>
        <hr />
        <HookedForm formHook={form} className="space-y-4">
          <SelectField
            placeholder="Select a template"
            name="emailTemplate"
            label="Email template"
            options={[
              { label: 'Email', value: 'email' },
              { label: 'Text', value: 'sms' },
            ]}
          />
          <SelectField
            placeholder="Select template"
            name="textTemplate"
            label="Text template"
            options={[
              { label: 'Email', value: 'email' },
              { label: 'Text', value: 'sms' },
            ]}
          />
        </HookedForm>
      </div>
      <div className="bg-white w-full max-w-[800px] p-4 space-y-4">
        <div>
          <h4 className="text-sm font-semibold">Follow-ups</h4>
          <p className="text-xs text-base-coolGray">
            You can also send follow-up requests after a while to customers who
            have not left a review.
          </p>
        </div>
        <hr />
        <HookedForm formHook={form} className="space-y-4">
          <SwitchField
            name="sendFollowUp"
            label={<span>Send follow-up</span>}
          />
          <SwitchField
            name="useAi"
            label={
              <span>
                Use AI to generate texts for follow-up emails and texts
              </span>
            }
          />
          <p className="text-sm">Send follow-up</p>

          {followUpRows.map((rowIndex, index) => (
            <div key={rowIndex} className="flex gap-4 items-center">
              <InputField
                name={`when${index}`}
                value="After"
                disabled
                className="!w-[100px] opacity-90 shrink-0"
                containerClass="!w-fit"
              />
              <InputField
                type="number"
                name={`followUpDays${rowIndex}`}
                defaultValue={1}
              />
              <SelectField
                options={[
                  { label: 'Day(s)', value: 'days' },
                  { label: 'Week(s)', value: 'weeks' },
                ]}
                name={`followUpPeriod${rowIndex}`}
              />

              {index === followUpRows.length - 1 && followUpRows.length < 3 && (
                <Button
                  kind="secondary"
                  type="button"
                  className="!min-w-fit"
                  onClick={addFollowUpRow}
                >
                  <AddIcon className="h-6 w-6" />
                </Button>
              )}

              {index > 0 && (
                <Button
                  kind="secondary"
                  className="!min-w-fit"
                  type="button"
                  onClick={() => removeFollowUpRow(index)}
                >
                  <CloseIcon className="h-6 w-6" />
                </Button>
              )}
            </div>
          ))}

          {followUpRows.length === 3 && (
            <p className="text-xs text-gray-500">
              Maximum of 3 follow-ups allowed
            </p>
          )}
        </HookedForm>
      </div>
      <div className="flex justify-between mt-4 w-full max-w-[800px]">
        <Button kind="secondary" type="button" onClick={handleBack}>
          Back: Select Customers
        </Button>
        <Button
          type="button"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creating...' : 'Create Campaign'}
        </Button>
      </div>
    </div>
  );
};
