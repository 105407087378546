import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import ViewIcon from '@app/assets/icons/view-campaign.svg?react';
import DotsIcon from '@app/assets/icons/menu/dot-vertical.svg?react';
import BinIcon from '@app/assets/icons/bin.svg?react';
import DuplicateIcon from '@app/assets/icons/duplicate.svg?react';
import CancelIcon from '@app/assets/icons/cancel.svg?react';
import PreviewIcon from '@app/assets/icons/preview.svg?react';

export const CampaignMenu = ({
  deleteCampaign,
}: {
  deleteCampaign?: () => void;
}) => {
  return (
    <Menu>
      <MenuButton className="group/user flex items-center justify-center gap-2 p-2">
        <DotsIcon />
      </MenuButton>
      <MenuItems
        transition
        anchor="left start"
        className="p-2 bg-white shadow-lg mt-2 min-w-[220px] transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 rounded-lg border file:border-base-antiFlash"
      >
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/`}
          >
            <ViewIcon className="[&_path]:stroke-brand-darkBlue3" /> Open
            campaign
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/`}
          >
            <PreviewIcon className="[&_path]:stroke-brand-darkBlue3" /> Open
            preview
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/`}
          >
            <DuplicateIcon className="[&_path]:stroke-brand-darkBlue3" />{' '}
            Duplicate as draft
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            to={`/`}
          >
            <CancelIcon className="[&_path]:stroke-system-error" /> Cancel
            campaign
          </Link>
        </MenuItem>
        <MenuItem>
          <p
            className="flex items-center gap-2 p-2 rounded-lg data-[focus]:bg-brand-lightBlue4 text-xs w-full"
            onClick={deleteCampaign}
          >
            <BinIcon className="[&_path]:stroke-system-error" /> Delete campaign
          </p>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};
