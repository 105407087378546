import { useState } from 'react';
import PencilIcon from '@app/assets/icons/pencil.svg?react';

type FieldConfig = {
  type: string;
  placeholder: string;
  Component?: 'input' | 'textarea';
};

const fieldConfigs: Record<string, FieldConfig> = {
  firstName: { type: 'text', placeholder: 'Enter first name' },
  lastName: { type: 'text', placeholder: 'Enter last name' },
  email: { type: 'email', placeholder: 'example@email.com' },
  phoneNumber: { type: 'tel', placeholder: '(123) 456-7890' },
  notes: {
    type: 'text',
    placeholder: 'Add notes (optional)',
    Component: 'textarea',
  },
};

const EditableField = ({
  field,
  value,
  onChange,
  config,
}: {
  field: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  config: FieldConfig;
}) => {
  const Component = config.Component || 'input';
  return (
    <Component
      type={config.type}
      value={value}
      onChange={onChange}
      className="w-full p-1 border rounded"
      required={field !== 'notes'}
      placeholder={config.placeholder}
    />
  );
};

export const ImportCustomerRow = ({
  id,
  firstName,
  lastName,
  email,
  phoneNumber,
  notes,
  updateCustomer,
}: {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  updateCustomer?: (customer: number, field: number, data: string) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({
    firstName,
    lastName,
    email,
    notes,
    phoneNumber,
  });

  const handleEdit = () => {
    setIsEditing(!isEditing);
    if (isEditing && updateCustomer) {
      const fieldsInOrder = [
        editData.firstName,
        editData.lastName,
        editData.email,
        editData.phoneNumber,
        editData.notes,
      ];

      fieldsInOrder.forEach((value, index) => {
        updateCustomer(id, index + 1, value);
      });
    }
  };

  const handleInputChange =
    (field: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEditData((prev) => ({ ...prev, [field]: e.target.value }));
    };

  const renderCell = (field: keyof typeof editData) => (
    <td className="p-2 text-sm">
      {isEditing ? (
        <EditableField
          field={field}
          value={editData[field]}
          onChange={handleInputChange(field)}
          config={fieldConfigs[field]}
        />
      ) : (
        <p>{editData[field]}</p>
      )}
    </td>
  );

  return (
    <tr id={`row-${id}`}>
      <td className="text-sm text-base-davyGray p-2">{id}</td>
      {renderCell('firstName')}
      {renderCell('lastName')}
      {renderCell('email')}
      {renderCell('phoneNumber')}
      {renderCell('notes')}
      <td>
        <button onClick={handleEdit} className="p-2">
          <span className="sr-only">Edit</span>
          <PencilIcon />
        </button>
      </td>
    </tr>
  );
};
