import GoogleIcon from '@app/assets/icons/Google.svg?react';
import ReplyIcon from '@app/assets/icons/reply.svg?react';
import { BusinessReviews } from '@app/models/reviews';
import { getTimeAgo } from '@app/utils/utils';
import clsx from 'clsx';

export const PreviewReview = ({
  active,
  review,
}: {
  active: boolean;
  review: BusinessReviews;
}) => {
  return (
    <div
      className={clsx(
        'p-4 space-y-2 border-b border-b-base-ghostWhite cursor-pointer relative',
        { 'side-tab bg-brand-lightBlue4': active }
      )}
    >
      <div className="flex justify-between gap-2 items-center">
        <span>
          <GoogleIcon width={15} height={15} />
        </span>
        <span className="bg-system-successLight text-system-success p-1 text-xs rounded-sm ml-2 mr-auto">
          New
        </span>
        <span>
          <ReplyIcon />
        </span>
        <span className="text-xs text-base-coolGray">
          {getTimeAgo(new Date(review.createdAt))}
        </span>
      </div>
      <div>
        <h5 className="font-semibold text-sm">
          {review?.customer?.firstName} {review?.customer?.lastName}
        </h5>
      </div>
      <div>
        <p className="max-h-9 overflow-hidden text-xs text-base-davyGray leading-5">
          {review.review}
        </p>
      </div>
    </div>
  );
};
