import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getReviewById = async (id: string) => {
  return apiInstance.get(`${ApiRoutes.Reviews}/${id}`);
};

export const getPaginatedReviews = async ({
  limit,
  page,
  order,
  sortBy,
  text,
}: {
  page: number;
  limit: number;
  sortBy?: 'createdAt' | 'customer' | 'photos' | 'rating';
  order?: 'ASC' | 'DESC';
  text?: string;
}) => {
  return apiInstance.get(`${ApiRoutes.Reviews}/`, {
    params: { page, limit, sortBy, order, text },
  });
};

export const createReview = async (data: {
  requestId: string;
  rating: string;
  images?: File[];
  comment?: string;
}) => {
  const formData = new FormData();

  if (data?.images) {
    data?.images.forEach((image: File) => {
      formData.append('files', image);
    });
  }

  formData.append('rating', data?.rating);
  formData.append('review', data?.comment ?? '');
  formData.append('requestId', data?.requestId);

  return apiInstance.post(`${ApiRoutes.Reviews}/`, formData);
};
