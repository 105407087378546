import { Link } from 'react-router-dom';
import { Button } from '@app/components';
import GoodReviewIllustration from '@app/assets/illustrations/good-review.svg?react';
import FacebookSquare from '@app/assets/icons/facebook-white-square.svg?react';
import TrustpilotSquare from '@app/assets/icons/trustpilot-white-square.svg?react';

export const LeaveReviewGood = () => {
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6 gap-6">
      <div className="bg-white text-center space-y-8 rounded max-w-[845px] w-full py-6 px-20 flex justify-center items-center flex-col mt-auto">
        <div className="flex items-center justify-center gap-2">
          <div
            className="w-12 h-12 flex items-center justify-center  rounded-lg shrink-0"
            style={{ color: `#424244`, background: `#42424414` }}
          >
            <span className="font-bold text-lg">{'DevX'.charAt(0)}</span>
          </div>
          <div className="flex flex-col">
            <h5 className="font-bold mr-auto break-all text-lg leading-none">
              DevX
            </h5>
          </div>
        </div>
        <h4 className="font-extrabold text-4xl">Thank you for you review</h4>
        <p className="text-base-coolGray text-base">
          We make it easy for your to share your exact review on our other
          platforms, just click the buttons below, it helps us out tremendously!
        </p>
        <div className="w-fit">
          <GoodReviewIllustration />
        </div>
        <hr className="border-base-coolGray" />
        <div className="text-left w-full">
          <h5 className="font-bold text-lg">
            Leave the same review on another platforms
          </h5>
          <p className="text-base-coolGray">
            You don't need to re-enter anything
          </p>
        </div>
        <div className="space-y-2 w-full">
          <Button
            leftNode={<FacebookSquare />}
            className="font-semibold bg-[#3A559F] w-full flex items-center justify-between"
          >
            <span className="mx-auto">Leave the same review on Facebook</span>
          </Button>
          <Button
            leftNode={<TrustpilotSquare />}
            className="font-semibold bg-[#00B67A]  w-full"
          >
            <span className="mx-auto">Leave the same review on Trustpilot</span>
          </Button>
        </div>
      </div>

      <p className="mt-auto text-base-coolGray">
        Powered by
        <Link to="/sign-up" className="ml-2 text-brand-baseBlue font-bold">
          Echo Ratings
        </Link>
      </p>
    </div>
  );
};
