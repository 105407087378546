import {
  BaseDialog,
  Button,
  ContactFilters,
  CustomerTable,
  HookedForm,
  InputField,
  Loading,
  MultipleSelectField,
  PhoneField,
  TextField,
} from '@app/components';
import { useNavigate } from 'react-router-dom';
import { useContactsPage } from './Contacts.hooks';
import { ListInterface, TagInterface } from '@app/models/util';
import ImportFileIcon from '@app/assets/icons/file-down.svg?react';
import AddIcon from '@app/assets/icons/add.svg?react';
import CRMIcon from '@app/assets/icons/crm.svg?react';
import UserIcon from '@app/assets/icons/user.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import NoCustomerImage from '@app/assets/icons/no-customers.svg?react';
import SearchIcon from '@app/assets/icons/search.svg?react';
import { CustomerInterface } from '@app/models/business';

export const Contacts = () => {
  const {
    createCustomerOpen,
    handleCreateCustomerOpen,
    createCustomerForm,
    submitCreateCustomer,
    isPending,
    customersData,
    isLoading,
    listsAndTags,
    isLoadingListsAndTags,
    isPendingDeleteCustomers,
    handleLimitChange,
    handleNextPage,
    handlePreviousPage,
    filtersForm,
    handleDeleteCustomers,
    handleSortingTable,
    deleteCustomerOpen,
    handleAddListOpen,
    handleDeleteCustomerOpen,
    handleScheduleCampaingOpen,
    handleCheckboxSelect,
    getSelectedCustomers,
    handleAddList,
    addListOpen,
    batchListAddForm,
    error,
    refetch,
    selectedCustomer,
    searchWatch,
  } = useContactsPage();
  const navigate = useNavigate();
  const currentPage = filtersForm.watch('page') ?? 1;

  return (
    <>
      {isLoading && <Loading />}

      {!isLoading && !error && (
        <div>
          <div className="bg-white border-b border-b-base-ghostWhite ">
            <div className="container mx-auto p-4 flex flex-col md:flex-row justify-between items-center">
              <h2 className="font-bold text-[28px]">Contacts</h2>
              <div className="flex gap-2 flex-col md:flex-row">
                <Button
                  kind="secondary"
                  leftNode={<CRMIcon />}
                  className="text-sm font-semibold"
                >
                  CRM connection
                </Button>
                <Button
                  onClick={() => navigate('/customers/contacts/import-file')}
                  leftNode={<ImportFileIcon />}
                  className="text-sm font-semibold"
                >
                  Import from file
                </Button>
                <Button
                  onClick={handleCreateCustomerOpen}
                  leftNode={<AddIcon />}
                  className="text-sm font-semibold"
                >
                  Add new customer
                </Button>
              </div>
            </div>

            <div className="container mx-auto px-4 py-2">
              <ContactFilters
                filterForm={filtersForm}
                lists={listsAndTags?.lists ?? []}
                tags={listsAndTags?.tags ?? []}
              />
            </div>
          </div>
          {!isLoading && (
            <div className="bg-white">
              <div className="container mx-auto py-6 px-4 flex flex-col md:flex-row justify-between items-end md:items-center gap-4">
                <h3 className="text-2xl font-bold">
                  {customersData?.data?.count} contacts
                </h3>
                <div>
                  <HookedForm formHook={filtersForm}>
                    <InputField name="search" placeholder="Search" />
                  </HookedForm>
                </div>
              </div>
            </div>
          )}
          <div className="p-4 container mx-auto overflow-auto">
            {isLoadingListsAndTags && isLoading && <Loading />}
            {customersData?.data?.customers?.length === 0 &&
              !isLoading &&
              searchWatch && (
                <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
                  <div className="rounded-lg bg-white p-4">
                    <SearchIcon />
                  </div>
                  <p className="font-bold">No customers</p>
                  <p className="text-sm text-base-coolGray text-center">
                    No customers match your current filters.
                    <br /> Consider adjusting them.
                  </p>
                </div>
              )}
            {customersData?.data?.customers?.length === 0 &&
              !isLoading &&
              !searchWatch && (
                <div className="flex items-center justify-center flex-col max-w-[350px] mx-auto space-y-4 h-full mt-20">
                  <NoCustomerImage />
                  <p className="font-bold">No customers</p>
                  <p className="text-sm text-base-coolGray text-center">
                    You currently don't have any customers.
                    <br />
                    Connect your CRM system to EchoRatings and synchronize your
                    customer list or add a customer manually.
                  </p>
                  <Button
                    leftNode={<CRMIcon className="[&_path]:stroke-white" />}
                  >
                    CRM connection
                  </Button>
                  <Button
                    leftNode={<AddIcon />}
                    onClick={handleCreateCustomerOpen}
                  >
                    Add new customer
                  </Button>
                </div>
              )}
            {customersData?.data?.customers?.length > 0 && !isLoading && (
              <CustomerTable
                handleSortingTable={handleSortingTable}
                externalForm={filtersForm}
                handleLimitChange={handleLimitChange}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePreviousPage}
                customers={customersData?.data?.customers ?? []}
                totalCustomers={customersData?.data?.count ?? 0}
                currentPage={currentPage}
                handleAddToList={handleAddListOpen}
                handleDeleteCustomer={handleDeleteCustomerOpen}
                handleScheduleCampaing={handleScheduleCampaingOpen}
                handleCustomerSelection={handleCheckboxSelect}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      )}
      <BaseDialog
        size="max-w-2xl"
        open={createCustomerOpen}
        onClose={handleCreateCustomerOpen}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Create new customer</h5>
            <p className="text-base-coolGray text-sm">
              Fill in the fields below and create a new customer
            </p>
          </div>
          <div>
            <button type="button" onClick={handleCreateCustomerOpen}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={createCustomerForm}
            onSubmit={submitCreateCustomer}
            className="space-y-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <InputField name="firstName" label="First name" />
              <InputField name="lastName" label="Last name" />
              <InputField name="email" label="Email" />
              <PhoneField name="phoneNumber" isOptional label="Phone Number" />
              <MultipleSelectField
                name="lists"
                label="Add to list(s)"
                isOptional
                options={listsAndTags?.lists?.map((item: ListInterface) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
              <MultipleSelectField
                name="tags"
                label="Add tag(s)"
                isOptional
                options={listsAndTags?.tags?.map((item: TagInterface) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>
            <TextField name="notes" label="Notes" isOptional />
            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                type="button"
                onClick={handleCreateCustomerOpen}
              >
                Cancel
              </Button>
              <Button
                disabled={isPending}
                loading={isPending}
                className="w-full"
                type="submit"
              >
                Create customer
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
      <BaseDialog
        size="max-w-2xl"
        open={deleteCustomerOpen}
        onClose={handleDeleteCustomerOpen}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto my-auto">
            <h5 className="font-bold text-lg">Delete customers</h5>
          </div>
          <div>
            <button type="button" onClick={() => handleDeleteCustomerOpen()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <p className="text-base-davyGray text-sm">
            Are you sure you want to delete these customers?
          </p>
          <p className="text-base-davyGray text-sm">
            They will be deleted from all locations in EchoRatings. Customers
            connected through the CRM system will not be deleted from your CRM.
          </p>
          <ul className="max-h-[400px] overflow-y-auto">
            <div className="grid grid-cols-3 gap-4 my-6">
              {getSelectedCustomers().map((customer: CustomerInterface) => {
                return (
                  <li
                    key={customer?.id}
                    className="text-sm font-medium flex gap-2 items-center"
                  >
                    <div className="w-6 h-6 bg-base-antiFlash flex items-center justify-center rounded-sm shrink-0">
                      <UserIcon
                        width={15}
                        height={15}
                        className="[&_path]:stroke-base-coolGray"
                      />
                    </div>
                    <span className="break-all">
                      {customer?.firstName} {customer?.lastName}
                    </span>
                  </li>
                );
              })}
            </div>
          </ul>
          <div className="flex items-center gap-4 justify-center">
            <Button
              kind="secondary"
              className="w-full"
              type="button"
              onClick={() => handleDeleteCustomerOpen()}
            >
              Cancel
            </Button>
            <Button
              kind="danger"
              disabled={isPendingDeleteCustomers}
              loading={isPendingDeleteCustomers}
              className="w-full"
              type="submit"
              onClick={handleDeleteCustomers}
            >
              Delete everywhere
            </Button>
          </div>
        </div>
      </BaseDialog>
      <BaseDialog
        size="max-w-2xl"
        open={addListOpen}
        onClose={handleAddListOpen}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Add to list(s)</h5>
            <p className="text-base-coolGray text-sm">
              Select the list(s) to which you want to add these customer(s)
            </p>
          </div>
          <div>
            <button type="button" onClick={() => handleAddListOpen()}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div>
          <div className="grid grid-cols-2 gap-8 divide-x min-h-[200px] relative">
            <div className="absolute left-[49.5%] top-2">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#E7EAF4"
                />
                <path
                  d="M20.8333 15.625L25 20M25 20L20.8333 24.375M25 20L15 20"
                  stroke="#8B919F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="px-6">
              {selectedCustomer ? (
                <div className="flex items-center gap-2">
                  <div className="w-6 h-6 bg-base-antiFlash flex items-center justify-center rounded-sm shrink-0">
                    <UserIcon
                      width={15}
                      height={15}
                      className="[&_path]:stroke-base-coolGray"
                    />
                  </div>
                  <span>
                    {selectedCustomer?.firstName} {selectedCustomer?.lastName}
                  </span>
                </div>
              ) : (
                <ul className="max-h-[400px] overflow-y-auto">
                  <div className="grid grid-cols-2 gap-4 ">
                    {getSelectedCustomers().map(
                      (customer: CustomerInterface, index: number) => (
                        <li
                          key={index}
                          className="text-sm font-medium flex gap-2 items-center"
                        >
                          <div className="w-6 h-6 bg-base-antiFlash flex items-center justify-center rounded-sm">
                            <UserIcon
                              width={15}
                              height={15}
                              className="[&_path]:stroke-base-coolGray"
                            />
                          </div>
                          <span className="break-all">
                            {customer?.firstName} {customer?.lastName}
                          </span>
                        </li>
                      )
                    )}
                  </div>
                </ul>
              )}
            </div>
            <div className="px-8">
              <HookedForm formHook={batchListAddForm}>
                <MultipleSelectField
                  name="listsToAdd"
                  options={listsAndTags?.lists?.map((item: ListInterface) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </HookedForm>
            </div>
          </div>
          <div className="flex items-center gap-4 justify-center p-4">
            <Button
              kind="secondary"
              className="w-full"
              type="button"
              onClick={() => handleAddListOpen()}
            >
              Cancel
            </Button>
            <Button
              disabled={isPendingDeleteCustomers}
              loading={isPendingDeleteCustomers}
              className="w-full"
              type="submit"
              onClick={handleAddList}
            >
              Add to list(s)
            </Button>
          </div>
        </div>
      </BaseDialog>
    </>
  );
};
