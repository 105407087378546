import { CustomerInterface } from '@app/models/business';
import { ListInterface } from '@app/models/util';
import { getCustomers } from '@app/services/customers';
import { getList, getListCustomers, updateList } from '@app/services/list';
import { createListValidation } from '@app/utils/validations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useListViewPage = () => {
  const navigate = useNavigate();
  const param = useParams();
  const [activeSubpage, setActivePage] = useState('1');
  const [listUpdateModal, setListUpdateModal] = useState(false);
  const listUpdateForm = useForm({ resolver: createListValidation });
  const [listCustomerQuery, setListCustomerQuery] = useState({
    page: 1,
    limit: 10,
  });

  const {
    data: listData,
    isLoading: isLoadingList,
    refetch: refetchList,
  } = useQuery({
    queryKey: [`list-${param.id}`],
    queryFn: () => getList(param?.id as string),
    enabled: !!param.id,
  });

  const {
    data: listCustomers,
    isLoading: isLoadingCustomers,
    refetch: refetchCustomers,
  } = useQuery({
    queryKey: [`list-${param.id}-customers`],
    queryFn: () => {
      const limit = listCustomerQuery?.limit ?? 10;
      const page = listCustomerQuery?.page ?? 1;
      const skip = (page - 1) * limit;

      return getListCustomers(param?.id as string, {
        pagination: { limit, page, skip },
      });
    },

    enabled: !!param.id,
  });

  const { data: customers, isLoading: customersLoading } = useQuery({
    queryKey: ['customers-all'],
    queryFn: () => getCustomers(),
  });

  const handleTabChange = (value: string) => {
    setActivePage(value);
  };

  const handleListModal = () => {
    setListUpdateModal(!listUpdateModal);
  };

  const handleNextPage = () => {
    setListCustomerQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const handlePrevPage = () => {
    setListCustomerQuery((prev) => ({
      ...prev,
      page: prev.page === 0 ? 0 : prev.page - 1,
    }));
  };

  const handleLimit = (value: any) => {
    setListCustomerQuery((prev) => ({ ...prev, limit: value }));
  };

  const { isPending, mutateAsync: update } = useMutation({
    mutationKey: [`update-list-${param.id}`],
    mutationFn: (data: Partial<ListInterface>) =>
      updateList(param.id as string, data),
    onSuccess: () => {
      refetchList();
      handleListModal();
      toast.success('List updated successfully!');
    },
  });

  useEffect(() => {
    if (listData?.data?.list) {
      listUpdateForm.reset({
        ...listData.data?.list,
        customers:
          listCustomers?.data?.customers?.length > 0
            ? listCustomers?.data?.customers?.map(
                (cus: CustomerInterface) => cus.id
              )
            : [],
      });
    }
  }, [listData, listCustomers]);

  useEffect(() => {
    refetchCustomers();
  }, [listCustomerQuery.limit, listCustomerQuery.page]);

  const handleListUpdate = async (values: FieldValues) => {
    await update({ ...values, customersId: values.customers });

    refetchCustomers();
  };

  return {
    navigate,
    param,
    listData,
    listCustomers,
    isLoadingCustomers,
    isLoadingList,
    isPending,
    handleTabChange,
    listUpdateForm,
    activeSubpage,
    listUpdateModal,
    handleListUpdate,
    handleListModal,
    handleNextPage,
    handlePrevPage,
    handleLimit,
    customers,
    customersLoading,
    listCustomerQuery,
    refetchCustomers,
  };
};
