import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth';
import businessReducer from './features/business';
import plansReducer from './features/plan';
import campaignFormReducer from './features/campaignForm';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    business: businessReducer,
    plans: plansReducer,
    campaignForm: campaignFormReducer,
  },
});

// Infer the `RootState`,  `AppDispatch`, and `AppStore` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
