import { useState } from 'react';
import FullStarIcon from '@app/assets/icons/star-full.svg?react';
import HalfStarIcon from '@app/assets/icons/star-half.svg?react';
import EmptyStarIcon from '@app/assets/icons/star-empty.svg?react';
import clsx from 'clsx';

interface StarProps {
  onClick: () => void;
  active?: boolean;
  half?: boolean;
}

const defaultSize = { width: 36, height: 36 };

export const Star = ({ onClick, active, half }: StarProps) => {
  const [hover, setHover] = useState(false);

  const getIcon = () => {
    if (active)
      return (
        <FullStarIcon
          {...defaultSize}
          className="[&_path]:fill-brand-baseBlue"
        />
      );
    if (half) return <HalfStarIcon {...defaultSize} />;
    if (hover)
      return (
        <FullStarIcon
          {...defaultSize}
          className="[&_path]:fill-brand-baseBlue"
        />
      );
    return <EmptyStarIcon {...defaultSize} />;
  };

  return (
    <div
      className={clsx(
        'w-14 h-14 flex items-center justify-center border border-base-antiFlash rounded-[4px] cursor-pointer',
        { 'bg-brand-lightBlue4': hover || active }
      )}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {getIcon()}
    </div>
  );
};
