import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@app/assets/icons/back.svg?react';
import { Button } from '@app/components';
import ArrowLeft from '@app/assets/icons/arrow-left.svg?react';
import ArrowRight from '@app/assets/icons/arrow-right.svg?react';
import { CreateCampaign as CreateCampaignRoutes } from '@app/utils/consts';
import { useMemo } from 'react';
import { CreateCampaignSettings } from './CreateCampaignSettings';
import { CreateCampaignCustomers } from './CreateCampaignCustomers';
import { CreateCampaignCampaign } from './CreateCampaignCampaign';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

export const CreateCampaign = ({ page }: { page: CreateCampaignRoutes }) => {
  const navigate = useNavigate();
  const campaignCreationForm = useForm();

  const handleNext = () => {
    switch (page) {
      case CreateCampaignRoutes.SETTINGS: {
        navigate('/campaigns/create/customers');
        break;
      }
      case CreateCampaignRoutes.CUSTOMERS: {
        navigate('/campaigns/create/campaign');
        break;
      }
      case CreateCampaignRoutes.CAMPAIGN: {
        break;
      }
      default: {
        navigate('/campaigns/create/settings');
      }
    }
  };

  const handlePrevious = () => {
    switch (page) {
      case CreateCampaignRoutes.SETTINGS: {
        break;
      }
      case CreateCampaignRoutes.CUSTOMERS: {
        navigate('/campaigns/create/settings');
        break;
      }
      case CreateCampaignRoutes.CAMPAIGN: {
        navigate('/campaigns/create/customers');
        break;
      }
      default: {
        navigate('/campaigns/create/settings');
      }
    }
  };

  const renderPage = useMemo(() => {
    switch (page) {
      case CreateCampaignRoutes.SETTINGS: {
        return <CreateCampaignSettings form={campaignCreationForm} />;
      }
      case CreateCampaignRoutes.CUSTOMERS: {
        return <CreateCampaignCustomers form={campaignCreationForm} />;
      }
      case CreateCampaignRoutes.CAMPAIGN: {
        return <CreateCampaignCampaign form={campaignCreationForm} />;
      }
      default: {
        return <CreateCampaignSettings form={campaignCreationForm} />;
      }
    }
  }, [page]);

  return (
    <div>
      <div className="bg-white border-b border-b-base-ghostWhite ">
        <div className="container mx-auto p-4 flex justify-between items-center">
          <h2 className="font-bold text-[28px]">
            <button onClick={() => navigate('/campaigns')} className="mr-2">
              <ArrowBackIcon />
            </button>
            Create Campaign
          </h2>
        </div>
        <hr />
        <div className="container mx-auto p-4 flex justify-between items-center">
          <Button
            kind="secondary"
            onClick={handlePrevious}
            className="min-w-fit"
          >
            <ArrowLeft />
          </Button>
          <div className="flex gap-2 items-center">
            <button
              onClick={() => navigate('/campaigns/create/settings')}
              className={clsx(
                'border border-base-ghostWhite px-4 py-1 rounded-full text-sm',
                {
                  'border-brand-baseBlue bg-brand-lightBlue4':
                    page === CreateCampaignRoutes.SETTINGS,
                }
              )}
            >
              1. Settings
            </button>
            <ArrowRight />
            <button
              onClick={() => navigate('/campaigns/create/customers')}
              className={clsx(
                'border border-base-ghostWhite px-4 py-1 rounded-full text-sm',
                {
                  'border-brand-baseBlue bg-brand-lightBlue4':
                    page === CreateCampaignRoutes.CUSTOMERS,
                }
              )}
            >
              2. Customers
            </button>
            <ArrowRight />
            <button
              onClick={() => navigate('/campaigns/create/campaign')}
              className={clsx(
                'border border-base-ghostWhite px-4 py-1 rounded-full text-sm',
                {
                  'border-brand-baseBlue bg-brand-lightBlue4':
                    page === CreateCampaignRoutes.CAMPAIGN,
                }
              )}
            >
              3. Campaign
            </button>
          </div>
          <Button onClick={handleNext} className="min-w-fit">
            <ArrowRight className="[&_path]:stroke-[white]" />
          </Button>
        </div>
      </div>
      <div>{renderPage}</div>
    </div>
  );
};
