import { Switch } from '@headlessui/react';
import React, { InputHTMLAttributes } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface SwitchFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  name: string;
  value?: string;
  onCheckMark?: (value?: string) => void;
}

export const SwitchField = ({
  label,
  name,
  value: optionValue,
  onCheckMark,
  ...rest
}: SwitchFieldProps) => {
  const {
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name ?? ''}
      defaultValue={rest.defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <label className="flex items-center">
            <Switch
              checked={value}
              value="true"
              onChange={onChange}
              className="group flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-brand-baseBlue"
            >
              <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
            </Switch>
            <span className="ml-2 text-sm">{label}</span>
          </label>
        );
      }}
    />
  );
};
