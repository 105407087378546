import { useCustomerViewPage } from './CustomerView.hooks';
import {
  BaseDialog,
  Button,
  CheckSelectField,
  HookedForm,
  InputField,
  PhoneField,
  Tabs,
  TextField,
} from '@app/components';
import { CustomerTabs } from '@app/utils/consts';
import { ListPill, TagPill } from '@app/components/CustomerTable/CustomerTable';
import { ListInterface, OptionInterface, TagInterface } from '@app/models/util';
import UserIcon from '@app/assets/icons/user.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import ArrowBackIcon from '@app/assets/icons/back.svg?react';
import PencilIcon from '@app/assets/icons/pencil.svg?react';
import { useEffect, useState } from 'react';

export const CustomerView = () => {
  const {
    navigate,
    customerData,
    handleTabChange,
    customerUpdateForm,
    handleCustomerUpdate,
    customerUpdateModal,
    handleCustomerModal,
    isPending,
    listsAndTags,
  } = useCustomerViewPage();
  const [placeholderItems, setPlaceholderItems] = useState([
    { count: 0, name: 'Sent', percent: 0 },
    { count: 0, name: 'Opened', percent: 0 },
    { count: 0, name: 'Clicked', percent: 0 },
    { count: 0, name: 'Rated', percent: 0 },
    { count: 0, name: 'Feedback', percent: 0 },
    { count: 0, name: 'Unsubscribed', percent: 0 },
  ]);

  useEffect(() => {
    if (customerData) {
      const updated = placeholderItems.map((item) => {
        const newItem = { ...item };
        if (newItem.name === 'Sent' && customerData.data.customer.campaigns) {
          newItem.count = 1;
        }
        if (newItem.name === 'Opened' && customerData.data.customer.reviews) {
          newItem.count = customerData.data.customer.reviews.length;
        }
        if (newItem.name === 'Rated' && customerData.data.customer.reviews) {
          newItem.count = customerData.data.customer.reviews.filter(
            (review: { rating: number }) => review.rating
          ).length;
        }
        return newItem;
      });

      setPlaceholderItems(updated);
    }
  }, [customerData]);

  return (
    <>
      <div>
        <div className="bg-white border-b border-b-base-ghostWhite ">
          <div className="container mx-auto">
            <div className="flex justify-between items-center p-4">
              <h2 className="font-bold text-[28px]">
                <button
                  onClick={() => navigate('/customers/contacts')}
                  className="mr-2"
                >
                  <ArrowBackIcon />
                </button>
                {customerData?.data?.customer?.firstName}{' '}
                {customerData?.data?.customer?.lastName}
              </h2>
            </div>
            <div className="px-4">
              <Tabs
                tabs={CustomerTabs as unknown as OptionInterface[]}
                onClick={handleTabChange}
              />
            </div>
          </div>
        </div>
        <div className="container mx-auto p-4">
          <div className="grid grid-cols-1 md:grid-cols-8 gap-4 p-4">
            <div className="bg-white p-4 h-fit col-span-5 md:col-span-3 w-full">
              <div className="flex items-center justify-between">
                <h5 className="font-bold text-sm">Contact Information</h5>
                <button
                  onClick={handleCustomerModal}
                  className="bg-base-antiFlash p-2 rounded-lg"
                >
                  <PencilIcon />
                </button>
              </div>
              <hr className="my-4" />
              <div className="space-y-4">
                <table>
                  <tbody>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          ECHO ID
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {customerData?.data?.customer?.id}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          FIRST NAME
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {customerData?.data?.customer?.firstName}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          LAST NAME
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {customerData?.data?.customer?.lastName}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          EMAIL
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {customerData?.data?.customer?.email}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="w-[130px]">
                        <span className="text-xs text-base-coolGray">
                          PHONE NUMBER
                        </span>
                      </td>
                      <td>
                        <span className="text-sm font-medium">
                          {customerData?.data?.customer?.phoneNumber}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr className="my-6" />
                <div>
                  <h5 className="text-xs text-base-coolGray mb-2">LISTS</h5>
                  <div className="flex gap-2">
                    {customerData?.data?.customer?.lists?.map(
                      (list: ListInterface) => (
                        <ListPill key={list.name} name={list.name} />
                      )
                    )}
                  </div>
                </div>
                <hr className="my-6" />
                <div>
                  <h5 className="text-xs text-base-coolGray mb-2">TAGS</h5>
                  <div className="flex gap-2">
                    {customerData?.data?.customer?.tags?.map(
                      (tag: TagInterface) => (
                        <TagPill key={tag.name} name={tag.name} />
                      )
                    )}
                  </div>
                </div>
                <hr className="my-6" />
                <div>
                  <h5 className="text-xs text-base-coolGray mb-2">NOTES</h5>
                  <p className="text-base-coolGray text-sm">
                    {customerData?.data?.customer?.notes}
                  </p>
                </div>
                <hr className="my-6" />
                <div>
                  <h5 className="text-xs text-base-coolGray mb-2">SOURCES</h5>
                  <p className="text-base-coolGray text-sm">LOCAL</p>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 col-span-5 h-fit">
              <div>
                <h5 className="font-bold text-sm">
                  <div className="flex items-center justify-between">
                    <h5 className="font-bold text-sm py-2">Overview</h5>
                  </div>
                  <hr className="my-4" />
                  <div>
                    <h5 className="text-xs text-base-coolGray mb-2">
                      EMAIL CAMPAINGS
                    </h5>
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                      {placeholderItems.map((item) => (
                        <div
                          key={item.name}
                          className="bg-base-seasalt rounded-lg p-4 flex gap-2 flex-col"
                        >
                          <span className="text-2xl font-bold">
                            {item.count}
                          </span>
                          <p className="text-base-coolGray text-xs font-medium">
                            {item.name}
                          </p>
                          <span className="text-sm text-brand-darkBlue3">
                            {item.percent}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="my-6" />
                  <div>
                    <h5 className="text-xs text-base-coolGray mb-2">
                      SMS CAMPAINGS
                    </h5>
                    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                      {placeholderItems.map((item) => (
                        <div
                          key={item.name}
                          className="bg-base-seasalt rounded-lg p-4 flex gap-2 flex-col"
                        >
                          <span className="text-2xl font-bold">
                            {item.count}
                          </span>
                          <p className="text-base-coolGray text-xs font-medium">
                            {item.name}
                          </p>
                          <span className="text-sm text-brand-darkBlue3">
                            {item.percent}%
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseDialog
        size="max-w-2xl"
        open={customerUpdateModal}
        onClose={handleCustomerModal}
      >
        <div className="border-b border-b-base-ghostWhite p-6 mb-6 flex gap-2 items-start justify-between">
          <div></div>
          <div className="bg-base-antiFlash flex items-center p-2 rounded-lg">
            <UserIcon width={24} height={24} />
          </div>
          <div className="mr-auto">
            <h5 className="font-bold text-lg">Update customer</h5>
            <p className="text-base-coolGray text-sm">
              Fill in the fields below and updated selected customer
            </p>
          </div>
          <div>
            <button type="button" onClick={handleCustomerModal}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="px-6 pb-4">
          <HookedForm
            formHook={customerUpdateForm}
            onSubmit={handleCustomerUpdate}
            className="space-y-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <InputField name="firstName" label="First name" />
              <InputField name="lastName" label="Last name" />
              <InputField name="email" label="Email" />
              <PhoneField name="phoneNumber" isOptional label="Phone Number" />
              <CheckSelectField
                name="lists"
                label="Add to list(s)"
                isOptional
                options={listsAndTags?.lists?.map((item: ListInterface) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
              <CheckSelectField
                name="tags"
                label="Add tag(s)"
                isOptional
                options={listsAndTags?.tags?.map((item: TagInterface) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </div>
            <TextField name="notes" label="Notes" isOptional />
            <div className="flex items-center gap-4 justify-center ">
              <Button
                kind="secondary"
                className="w-full"
                type="button"
                onClick={handleCustomerModal}
              >
                Cancel
              </Button>
              <Button
                disabled={isPending}
                loading={isPending}
                className="w-full"
                type="submit"
              >
                Update customer
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseDialog>
    </>
  );
};
