import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getRequestById = async (id: string) => {
  return apiInstance.get(`${ApiRoutes.Requests}/${id}`);
};

export const getPaginatedRequests = async ({
  limit,
  page,
  order,
  sortBy,
  text,
}: {
  page: number;
  limit: number;
  sortBy?: 'createdAt' | 'customer' | 'photos' | 'rating';
  order?: 'ASC' | 'DESC';
  text?: string;
}) => {
  return apiInstance.get(`${ApiRoutes.Ratings}/`, {
    params: { page, limit, sortBy, order, text },
  });
};

export const deleteRequest = async (id: string) => {
  return apiInstance.delete(`${ApiRoutes.Ratings}/${id}`);
};
