import { Link } from 'react-router-dom';
import SadCloudIllustration from '@app/assets/illustrations/sad-cloud.svg?react';

export const LeaveReviewBad = () => {
  return (
    <div className="flex items-center justify-center flex-col min-h-screen py-6 gap-6">
      <div className="bg-white text-center space-y-8 rounded max-w-[845px] w-full py-6 px-20 flex justify-center items-center flex-col mt-auto">
        <div className="flex items-center justify-center gap-2">
          <div
            className="w-12 h-12 flex items-center justify-center  rounded-lg shrink-0"
            style={{ color: `#424244`, background: `#42424414` }}
          >
            <span className="font-bold text-lg">{'DevX'.charAt(0)}</span>
          </div>
          <div className="flex flex-col">
            <h5 className="font-bold mr-auto break-all text-lg leading-none">
              DevX
            </h5>
          </div>
        </div>
        <h4 className="font-extrabold text-4xl">
          We are very sorry that you had a bad experience!
        </h4>
        <p className="text-base-coolGray text-base">
          We will do our best to correct the negative aspects you have
          identified. Thank you for being transparent with us!
        </p>
        <div className="w-fit">
          <SadCloudIllustration />
        </div>
      </div>

      <p className="mt-auto text-base-coolGray">
        Powered by
        <Link to="/sign-up" className="ml-2 text-brand-baseBlue font-bold">
          Echo Ratings
        </Link>
      </p>
    </div>
  );
};
