import { ListInterface } from '@app/models/util';
import { useListRow } from './ListRow.hooks';
import { CheckboxField } from '../Fields/CheckboxField';
import { CustomerInterface } from '@app/models/business';
import { DateTime } from 'luxon';
import { ListMenu } from '../ListMenu/ListMenu';
import UsersIcon from '@app/assets/icons/grid/users.svg?react';
import FolderIcon from '@app/assets/icons/folder.svg?react';

export const ListRow = ({
  data,
  handleCheckbox,
  refresh,
}: {
  data: ListInterface;
  handleCheckbox: (value: string | undefined) => void;
  refresh?: () => void;
}) => {
  const { navigate, deleteListFn } = useListRow(data.id);
  const goToList = () => navigate(`/customers/lists/${data.id}`);

  return (
    <tr
      key={data.id}
      className="border-b border-base-antiFlash last-of-type:border-b-0 text-xs cursor-pointer hover:bg-brand-lightBlue3"
    >
      <td className="p-4 min-w-fit w-0">
        <CheckboxField onCheckMark={handleCheckbox} name="id" value={data.id} />
      </td>
      <td className="p-4 flex items-center gap-2" onClick={() => goToList()}>
        <div className="bg-base-antiFlash rounded-sm size-6 flex items-center justify-center">
          <FolderIcon />
        </div>
        {data.name}
      </td>
      <td className="p-4 text-base-davyGray" onClick={() => goToList()}>
        #{data.id}
      </td>
      <td className="p-4" onClick={() => goToList()}>
        <div className="flex items-center gap-2">
          {(data.customers ?? []).length > 0 && (
            <>
              <span className="p-1 inline-flex bg-base-antiFlash rounded-md">
                <UsersIcon width={15} height={15} />
              </span>
              <div className="inline-flex items-center gap-2">
                {data?.customers
                  ?.slice(0, 3)
                  ?.map(
                    (customer: CustomerInterface) =>
                      `${customer.firstName} ${customer.lastName}`
                  )
                  .join(', ')}
                {(data.customers ?? []).length > 3 && (
                  <span className="w-fit px-1 h-5 items-center justify-center inline-flex bg-brand-lightBlue1 rounded-md text-xs font-bold text-brand-darkBlue3">
                    + {(data.customers ?? []).length - 3}
                  </span>
                )}
              </div>
            </>
          )}
          {(data.customers ?? []).length === 0 && (
            <span>No customer added to data.</span>
          )}
        </div>
      </td>
      <td className="p-4 text-base-davyGray" onClick={() => goToList()}>
        {DateTime.fromISO(data.createdAt).toFormat('LLL dd, yyyy  HH:MM')}
      </td>
      <td className="p-4">
        <ListMenu
          deleteListAction={async () => {
            await deleteListFn();
            refresh && refresh();
          }}
          id={data.id}
        />
      </td>
    </tr>
  );
};
