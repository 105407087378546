import { CustomerInterface } from '@app/models/business';
import {
  CreateCustomerRequest,
  DeleteCustomersInterface,
} from '@app/models/requests';
import { useAppSelector } from '@app/redux/hooks';
import {
  createCustomer,
  deleteCustomers,
  getPaginatedCustomers,
  updateCustomers,
} from '@app/services/customers';
import { getLists } from '@app/services/list';
import { getTag } from '@app/services/tag';
import { createCustomerValidation } from '@app/utils/validations';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const handleFilters = (filtersForm: FieldValues) => {
  const filterValues = [
    'channelType',
    'status',
    'ratings',
    'lists',
    'tags',
  ].reduce((acc: Record<string, any>, key) => {
    const value = filtersForm.getValues(key);
    if (value && value !== 'all') {
      acc[key as string] = value;
    }
    return acc;
  }, {});

  return filterValues;
};

export const useContactsPage = () => {
  const [createCustomerOpen, setCreateCustomerOpen] = useState<boolean>(false);
  const [deleteCustomerOpen, setDeleteCustomerOpen] = useState<boolean>(false);
  const [scheduleCampaingOpen, setScheduleCampaingOpen] =
    useState<boolean>(false);
  const [addListOpen, setAddListOpen] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerInterface>();
  const [selected, setSelected] = useState<CustomerInterface[]>([]);
  const createCustomerForm = useForm({ resolver: createCustomerValidation });
  const batchListAddForm = useForm({ mode: 'onChange' });
  const businessState = useAppSelector((state) => state.business);
  const filtersForm = useForm({ mode: 'onChange' });

  const handleCreateCustomerOpen = () => {
    setCreateCustomerOpen(!createCustomerOpen);
    createCustomerForm.reset();
  };

  const handleDeleteCustomerOpen = (clearSelection?: boolean) => {
    setDeleteCustomerOpen(!deleteCustomerOpen);

    if (clearSelection) {
      filtersForm.setValue('selectedCustomers', []);
    }
  };

  const handleScheduleCampaingOpen = (clearSelection?: boolean) => {
    setScheduleCampaingOpen(!scheduleCampaingOpen);

    if (clearSelection) {
      filtersForm.setValue('selectedCustomers', []);
    }
  };

  const handleAddListOpen = (customer?: CustomerInterface) => {
    setAddListOpen(!addListOpen);

    setSelectedCustomer(!addListOpen ? customer : undefined);
  };

  const {
    data: customersData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['all-paginated-customers'],
    queryFn: () => {
      const limit = filtersForm.getValues('limit') ?? 10;
      const pageValue = Number(filtersForm.getValues('page') ?? 1);

      const page = Number(
        pageValue === 0 ? 1 : (filtersForm.getValues('page') ?? 1)
      );
      const skip = (page - 1) * limit;
      const search = filtersForm.getValues('search') ?? '';
      const sort = filtersForm.getValues('sort') ?? {
        field: 'createdAt',
        order: 'DESC',
      };

      const filters = handleFilters(filtersForm);

      return getPaginatedCustomers({
        text: search,
        pagination: { limit, page, skip },
        sort,
        ...filters,
      });
    },
  });

  const { data: listsAndTags, isLoading: isLoadingListsAndTags } = useQuery({
    queryKey: ['all-lists-and-tags'],
    queryFn: async () =>
      await Promise.all([getLists(), getTag()])
        .then((responses) => {
          return {
            lists: responses?.[0]?.data?.lists,
            tags: responses?.[1]?.data?.tags,
          };
        })
        .catch((err) => err),
  });

  const { mutate: addCustomer, isPending } = useMutation({
    mutationKey: ['add-customer'],
    mutationFn: (data: CreateCustomerRequest) =>
      createCustomer({
        ...data,
        businessId: businessState.selected?.id as string,
      }),
    onSuccess: () => {
      refetch();
      createCustomerForm.reset();
      handleCreateCustomerOpen();
    },
    onError: (err) => {
      toast.error('Error creating customer');
      console.error(err);
    },
  });

  const {
    mutateAsync: deleteCustomersFn,
    isPending: isPendingDeleteCustomers,
  } = useMutation({
    mutationKey: ['delete-customers'],
    mutationFn: (data: DeleteCustomersInterface) => deleteCustomers(data.ids),
    onSuccess: () => {
      refetch();
      filtersForm.setValue('selectedCustomers', []);
      setSelected([]);
      handleDeleteCustomerOpen();
    },
    onError: (err) => {
      toast.error('Error deleting customers');
      console.error(err);
    },
  });

  const { mutate: updateBatchLists, isPending: isAddingToLists } = useMutation({
    mutationKey: [`batch-list-customer`],
    mutationFn: (data: {
      customers: Partial<CustomerInterface>[];
      lists?: string[];
    }) => updateCustomers(data),
    onSuccess: () => {
      refetch();
      handleAddListOpen();
    },
  });

  const submitCreateCustomer = (values: FieldValues) => {
    addCustomer(values as CreateCustomerRequest);
  };

  const handleNextPage = () => {
    const currentPage = filtersForm.getValues('page');

    filtersForm.setValue('page', Number(currentPage) + 1);
  };

  const handlePreviousPage = () => {
    const currentPage = filtersForm.getValues('page');

    filtersForm.setValue('page', Number(currentPage) - 1);
  };

  const handleLimitChange = () => {};

  const handleDeleteCustomers = async () => {
    const selectedCustomers = filtersForm.watch('selectedCustomers') ?? [];

    await deleteCustomersFn({
      ids: selectedCustomers.map((cus: CustomerInterface) => cus.id),
    });
  };

  const handleScheduleCampaing = () => {};

  const getSelectedCustomers = () => {
    const selectedCustomers = filtersForm.watch('selectedCustomers') ?? [];

    return selectedCustomers;
  };

  const handleAddList = () => {
    const lists = batchListAddForm.getValues('listsToAdd');

    if (lists?.length > 0 && !selectedCustomer) {
      const customers = getSelectedCustomers();
      updateBatchLists({ customers, lists });
    }
    if (selectedCustomer) {
      updateBatchLists({
        customers: [selectedCustomer],
        lists,
      });
    }
  };

  const handleSortingTable = (field: string) => {
    const currentSort = filtersForm.getValues('sort');

    filtersForm.setValue('sort', {
      field,
      order: currentSort?.order === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  const resetSelectedCustomers = () => {
    filtersForm.setValue('selectAll', false);
    filtersForm.setValue('selectedCustomers', []);
    setSelected([]);
  };

  const resetAllSelected = () => {
    if (!selected.length) return;
    const updatedSelected = selected.filter(
      (selectedCustomer) =>
        !customersData?.data?.customers?.some(
          (cus: CustomerInterface) => cus.id === selectedCustomer.id
        )
    );

    setSelected(updatedSelected);
    filtersForm.setValue('selectedCustomers', updatedSelected);
  };

  const handleCheckboxSelect = (customerId?: string) => {
    if (!customerId) return;

    if (customerId === 'all') {
      const customers = customersData?.data?.customers ?? [];
      const customersId = customers.map((cus: CustomerInterface) => cus.id);
      const selectedCustomers = filtersForm.watch('selectedCustomers') ?? [];
      const selectedId = selectedCustomers.map(
        (cus: CustomerInterface) => cus.id
      );

      if (customersId.every((id: string) => selectedId.includes(id))) {
        resetAllSelected();
        return;
      } else {
        const newSelected = [
          ...selected,
          ...customers.filter(
            (cus: CustomerInterface) => !selectedId.includes(cus.id)
          ),
        ];

        setSelected(newSelected);
        filtersForm.setValue('selectedCustomers', newSelected);
      }
      return;
    }

    if (selected.some((cus) => cus.id === customerId)) {
      const updatedList = selected.filter((cus) => cus.id !== customerId);
      setSelected(updatedList);
      filtersForm.setValue('selectedCustomers', updatedList);
    } else {
      const customer = customersData?.data?.customers?.find(
        (cus: CustomerInterface) => cus.id === customerId
      );
      if (customer) {
        const updatedList = [...selected, customer];
        setSelected(updatedList);
        filtersForm.setValue('selectedCustomers', updatedList);
      }
    }
  };

  const pageWatch = filtersForm.watch('page');
  const limitWatch = filtersForm.watch('limit');
  const selectAllWatch = filtersForm.watch('selectAll');
  const searchWatch = filtersForm.watch('search');
  const channelTypeWatch = filtersForm.watch('channelType');
  const statusWatch = filtersForm.watch('status');
  const ratingsWatch = filtersForm.watch('ratings');
  const platformWatch = filtersForm.watch('platform');
  const listsWatch = filtersForm.watch('lists');
  const tagsWatch = filtersForm.watch('tags');
  const sortWatch = filtersForm.watch('sort');

  useEffect(() => {
    refetch();
  }, [pageWatch, limitWatch]);

  useEffect(() => {
    const customers = customersData?.data?.customers;
    if (selectAllWatch) {
      const selectedCustomers = customers?.map((cus: CustomerInterface) => cus);

      const updatedArray = new Set([...selected, ...selectedCustomers]);
      setSelected(Array.from(updatedArray));
      filtersForm.setValue('selectedCustomers', Array.from(updatedArray));
    } else {
      resetAllSelected();
    }
  }, [selectAllWatch]);

  useEffect(() => {
    filtersForm.setValue('page', 1);
    resetSelectedCustomers();

    refetch();
  }, [
    searchWatch,
    channelTypeWatch,
    statusWatch,
    ratingsWatch,
    platformWatch,
    listsWatch,
    tagsWatch,
    limitWatch,
  ]);

  useEffect(() => {
    refetch();
  }, [sortWatch]);

  return {
    createCustomerOpen,
    handleCreateCustomerOpen,
    createCustomerForm,
    submitCreateCustomer,
    isPending,
    isLoading,
    isPendingDeleteCustomers,
    customersData,
    listsAndTags,
    isLoadingListsAndTags,
    handleNextPage,
    handlePreviousPage,
    handleLimitChange,
    handleSortingTable,
    filtersForm,
    handleDeleteCustomers,
    handleScheduleCampaing,
    handleAddList,
    handleAddListOpen,
    handleDeleteCustomerOpen,
    handleScheduleCampaingOpen,
    handleCheckboxSelect,
    addListOpen,
    deleteCustomerOpen,
    scheduleCampaingOpen,
    getSelectedCustomers,
    batchListAddForm,
    isAddingToLists,
    error,
    refetch,
    selectedCustomer,
    searchWatch,
  };
};
