export const ApiBaseRoute = import.meta.env.VITE_APP_API_BASE;

export const ApiRoutes = {
  Analytics: '/analytics',
  Auth: '/auth',
  User: '/user',
  Plan: '/subscription',
  Business: '/business',
  Lists: '/lists',
  Tags: '/tags',
  Customers: '/customers',
  Integrations: '/integrations',
  Reviews: '/reviews',
  Service: '/service',
  Ratings: '/ratings',
  Requests: '/requests',
  Campaigns: '/campaigns',
};
