import { deleteList } from '@app/services/list';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export const useListRow = (id: string) => {
  const navigate = useNavigate();

  const { mutateAsync: deleteListFn, isPending } = useMutation({
    mutationKey: ['delete-list'],
    mutationFn: () => deleteList(id),
  });

  return { navigate, deleteListFn, isPending };
};
