import { CreateCampaignRequest } from '@app/models/requests';
import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const createCampaign = async (data: CreateCampaignRequest) => {
  return apiInstance.post(`${ApiRoutes.Campaigns}/create`, data);
};

export const getCampaigns = async ({
  page,
  limit,
  sortBy,
  sortOrder,
  statuses,
  channels,
  platforms,
  from,
  to,
}: {
  page: number;
  limit: number;
  sortBy: string | undefined;
  sortOrder: string;
  statuses?: string[];
  channels?: string[];
  platforms?: string[];
  from?: string;
  to?: string;
}) => {
  return apiInstance.get(`${ApiRoutes.Campaigns}/`, {
    params: {
      page,
      limit,
      sortBy,
      sortOrder,
      statuses,
      channels,
      platforms,
      from,
      to,
    },
  });
};

export const getCampaign = async (id: string) => {
  return apiInstance.get(`${ApiRoutes.Campaigns}/${id}`);
};

export const updateCampaign = async (
  id: string,
  data: Partial<CreateCampaignRequest>
) => {
  return apiInstance.put(`${ApiRoutes.Campaigns}/${id}`, data);
};

export const deleteCampaign = async (id: string) => {
  return apiInstance.delete(`${ApiRoutes.Campaigns}/${id}`);
};

export const addCustomerToCampaign = async (
  campaignId: string,
  customerId: string
) => {
  return apiInstance.post(
    `${ApiRoutes.Campaigns}/${campaignId}/customers/${customerId}`
  );
};

export const addScheduleToCampaign = async (
  campaignId: string,
  data: {
    startDate: string;
    frequency?: string;
    time?: string;
  }
) => {
  return apiInstance.post(
    `${ApiRoutes.Campaigns}/${campaignId}/schedules`,
    data
  );
};
