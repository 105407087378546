import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authSlice } from './auth';

export interface CampaignFormState {
  formData: Record<string, any>;
}

// Initialize with data from localStorage if available
const getInitialState = (): CampaignFormState => {
  try {
    const storedData = localStorage.getItem('campaignFormData');
    return {
      formData: storedData ? JSON.parse(storedData) : {},
    };
  } catch (error) {
    console.error('Failed to parse stored campaign form data', error);
    return { formData: {} };
  }
};

const initialState: CampaignFormState = getInitialState();

export const campaignFormSlice = createSlice({
  name: 'campaignForm',
  initialState,
  reducers: {
    updateFormData: (state, action: PayloadAction<Record<string, any>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
      // Save to localStorage
      localStorage.setItem('campaignFormData', JSON.stringify(state.formData));
    },
    resetForm: (state) => {
      state.formData = {};
      localStorage.removeItem('campaignFormData');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.logout, (state) => {
      state.formData = {};
      localStorage.removeItem('campaignFormData');
    });
  },
});

export const { updateFormData, resetForm } = campaignFormSlice.actions;

export default campaignFormSlice.reducer;
