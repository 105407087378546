import { apiInstance } from './api';
import { ApiRoutes } from './consts';

export const getCampaignsInformation = async () => {
  return apiInstance.get(`${ApiRoutes.Analytics}/campaigns`);
};

export const getCampaignsByTimeline = async (filter?: string) => {
  return apiInstance.get(`${ApiRoutes.Analytics}/timeline`, {
    params: { filter: filter ?? 'monthly' },
  });
};
